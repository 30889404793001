import { useEffect } from 'react';
import { BootResponse } from '../constants/apiResponses.types';
import useApi from './useApi';
import { useSetCssVars as setCss } from '@appcharge/shared-ui';

export default function useSetCssVars() {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  useEffect(() => {
    if (!publisherMetaData || !publisherMetaData.storeTheme) {
      return;
    }

    setCss({
      font: publisherMetaData.storeTheme.general?.font,
      buttonTextColor: publisherMetaData.storeTheme.storeScreen?.buttonTextColor,
      buttonColor: publisherMetaData.storeTheme.storeScreen?.buttonColor,
      bundleBorderColor: publisherMetaData.storeTheme.storeScreen?.bundleBorderColor,
      popupRatio: publisherMetaData.storeTheme.general?.popupRatio,
      document,
    });
  }, [publisherMetaData]);
}
