import { HeaderWrapper } from 'components/HeaderWrapper/HeaderWrapper';
import useApi from 'hooks/useApi';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import LogoutModal from 'components/LogoutModal/LogoutModal';
import {
  MessageBox,
  ProductData,
  ProductsCollectAnimation,
  StoreLayout,
  useIsDesktop,
  useIsLandscape,
} from '@appcharge/shared-ui';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  EBundlesInternalViewModel,
  ECheckoutPageEvent,
  ECollectType,
  EEventsType,
  EFeatureFlag,
  ELocalStorageKeys,
  ELoginEvents,
  EOrderStatus,
  EPopupSubType,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EQueryKeys,
  EQueryParams,
  ESessionStorageKeys,
  ESSEventType,
  EStorePhase,
  StoreBodyBlock,
} from 'constants/enums';
import {
  extractOfferMainDetails,
  extractRollingOfferMainDetails,
  getBadgeName,
  getPlatformData,
  getSelectedProduct,
} from 'utils';
import { webStorageUtil } from 'state/webStorage.state.service';
import useUrlQuery from 'hooks/useUrlQuery';
import './style.scss';
import useCustomEvents from 'hooks/useCustomEvents';
import { BundlesWrapper } from './components/BundlesWrapper';
import { Checkout } from 'components/Checkout/Checkout';
import useSaveToHomePage from 'hooks/useSaveToHomePage';
import AddToHomePage from 'components/AddToHomePage/AddToHomePage';
import { PlayerLevelWrapper } from './components/PlayerLevelWrapper';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { BannerWrapper } from './components/BannerWrapper';
import { MissingOffersBlock } from './components/MissingOffersBlock';
import { SpecialOffersWrapper } from './components/SpecialOffersWrapper';
import { useAppState } from 'state/hooks/app.state.hook';
import {
  CreateOrderPayload,
  IProductResponse,
  OfferDataForPublisherEvent,
} from 'constants/apiRequest.types';
import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { usePopupState } from 'state/hooks/popup.state.hook';
import { PopupsWrapper } from './components/PopupsWrapper';
import useLoginEvents from 'hooks/useLoginEvents';
import { RollingOffersWrapper } from './components/RollingOffersWrapper';
import { DailyBonusWrapper } from './components/DailyBonusWrapper';
import { useLoadImages } from './hooks/useLoadImages.hook';
import animationData from 'assets/animations/confetti-animation.json';
import Lottie from 'lottie-react';
import HashValidationErrorModal from 'components/HashValidationErrorModal/HashValidationErrorModal';
import { IntegrationProfilePicker } from '../login/Components/IntegrationProfilePicker';
import { useSSE } from 'hooks/useSSE';
import { useOffers } from './hooks/useOffers.hook';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'hooks/useEvent';
import { useEventBatchContext } from 'hooks/useEventBatch';
import { Z_INDEX } from 'constants/zIndex';
import useFEMonitoring from 'hooks/useFEMonitoring';
import { useQueryClient } from 'react-query';

const OFFERS_INTERVAL_TIME = 30000;
const POLLING_INTERVAL = 10000;

interface IShopPageProps {
  logout: (utmSource?: string) => void;
}

interface IOfferBalance {
  images: {
    product: string;
    productPrefix: string;
  };
  product: ProductData;
  quantity: string;
}

const DEFAULT_BODY_BLOCKS_ORDER: StoreBodyBlock[] = [
  StoreBodyBlock.dailyBonus,
  StoreBodyBlock.playerLevel,
  StoreBodyBlock.banners,
  StoreBodyBlock.specialOffers,
  StoreBodyBlock.rollingOffers,
  StoreBodyBlock.bundles,
];

const mapBlocksToComponents: Record<StoreBodyBlock, React.FC<any>> = {
  [StoreBodyBlock.dailyBonus]: DailyBonusWrapper,
  [StoreBodyBlock.playerLevel]: PlayerLevelWrapper,
  [StoreBodyBlock.banners]: BannerWrapper,
  [StoreBodyBlock.specialOffers]: SpecialOffersWrapper,
  [StoreBodyBlock.rollingOffers]: RollingOffersWrapper,
  [StoreBodyBlock.bundles]: BundlesWrapper,
};

export const ShopPage: React.FC<IShopPageProps> = ({ logout }) => {
  //store config states:
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  //order states:
  const [activeOrderId, setActiveOrderId] = useState<string>('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  //offer states:
  const [offersLoadedReported, setOffersLoadedReported] = useState<boolean>(false);

  const navigate = useNavigate();
  const [isSSE, setIsSSE] = useState(false);
  const API = useApi({ orderId: activeOrderId, isSSE: isSSE || false });
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;
  const from = useUrlQuery(EQueryParams.FROM);
  const campaignToken = useUrlQuery(EQueryParams.TOKEN);
  const [campaignOfferId, setCampaignOfferId] = useState<{ offerId: string }>();
  const [loadingFromCampaign, setLoadingFromCampaign] = useState(from === 'campaign');
  const customEvents = useCustomEvents();
  const [bodyBlocksOrder, setBodyBlocksOrder] = useState<StoreBodyBlock[]>([]);

  const fetchInterval = useRef(0);
  const { hasNoOffers } = useOffers();
  const { t } = useTranslation();
  const { openTooltipEventHandler } = useEvent();
  const { sendEvent } = useEventBatchContext();

  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { isDisplayAddToHomePageButton } = useSaveToHomePage(publisherMetaData);
  const { currentLanguage } = useLocalizationState();
  const { allImagesLoaded } = useLoadImages();
  const {
    setDisplayLoader,
    selectedOffer,
    setSelectedOffer,
    showConfetti,
    setShowConfetti,
    setFailedHashValidation,
    failedHashValidation,
    setBackgroundProps,
    utms,
    collectedProductsImages,
    setCollectedProductsImages,
    shouldDisplayProductsAnimation,
    isProfileEnabled,
    setIsProfileEnabled,
  } = useAppState();
  const { currentPopupDisplayed, setCurrentPopupDisplayed, popupIndexToCollect } = usePopupState();
  const { logToNewRelic } = useFEMonitoring();

  const utmSource = useMemo(() => utms?.utmSource, [utms?.utmSource]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!publisherMetaData?.storeTheme) return;
    setBackgroundProps({
      height: '',
      minHeight: '100vh',
    });
    const backgroundImage =
      isDesktop || isLandscape
        ? publisherMetaData.storeTheme.general.backgroundImageDesktop
        : publisherMetaData.storeTheme.general.backgroundImageMobile;

    document.body.style.setProperty('--background-image', `url(${backgroundImage})`);
    document.body.style.setProperty('z-index', `-${Z_INDEX.BASE}`);
    document.body.style.setProperty('position', 'relative');
  }, [publisherMetaData?.storeTheme, isDesktop, isLandscape]);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      if ((window as any).Helpshift) {
        (window as any).Helpshift('close');
      }
    });

    if ((window as any).Helpshift) {
      (window as any).Helpshift('close');
      (window as any).Helpshift('show');
    }
  }, []);

  useEffect(() => {
    if (publisherMetaData.supportConfiguration?.postLoginSnippet) {
      const script = document.createElement('script');
      script.src = publisherMetaData.supportConfiguration?.postLoginSnippet;
      document.head.appendChild(script);
      const interval = setInterval(() => {
        const iframe = document.getElementById('hs-web-sdk-iframe-launcher');
        const iframeDoc =
          (iframe as HTMLIFrameElement)?.contentDocument ||
          (iframe as HTMLIFrameElement)?.contentWindow?.document;
        if (iframeDoc) {
          iframeDoc.querySelector('button')?.addEventListener('click', () => {
            const url = new URL(window.location.href);
            url.searchParams.set('support', 'true');
            window.history.pushState({}, '', url);
          });
          clearInterval(interval);
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (!API.getOffers.data?.offers) return;

    if (!offersLoadedReported) {
      const offersExtractedData = [
        ...extractOfferMainDetails(API.getOffers.data.offers.promotions),
        ...extractOfferMainDetails(API.getOffers.data.offers.bundles),
        ...extractOfferMainDetails(API.getOffers.data.offers.popups),
        ...extractRollingOfferMainDetails(API.getOffers.data.offers.rolling),
      ];
      customEvents.sendCustomEvent(
        EEventsType.OFFERS_LOADED,
        {
          specialOffers: API.getOffers.data.offers.promotions?.length,
          bundles: API.getOffers.data.offers.bundles?.length,
          popups: API.getOffers.data.offers.popups?.length,
          offersData: offersExtractedData,
        },
        EStorePhase.POST_LOGIN
      );
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.OFFER,
        data: {
          eventName: EPublisherWebhookOfferEventType.OFFERS_LOADED,
          offers: offersExtractedData,
          ...customEvents.getEventsSharedProps(),
        },
      });
      setOffersLoadedReported(true);
    }
  }, [API.getOffers.data]);

  useEffect(() => {
    if (API.getOffers.isError) {
      customEvents.resetSession();
      logout();
    }
  }, [API.getOffers.isError]);

  useEffect(() => {
    document.getElementById('root')?.classList.remove('screen-lock');
  }, []);

  useEffect(() => {
    const handleFocus = () => keepAlive();
    const handleBlur = () => clearTimeout(fetchInterval.current);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [isDesktop, isLandscape, publisherMetaData]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const init = async () => {
      customEvents.sendCustomEvent(
        EEventsType.SHOP_LAND,
        {
          platform: getPlatformData(),
          source: webStorageUtil.get(ESessionStorageKeys.SOURCE),
          add_to_home_screen_appear: isDisplayAddToHomePageButton,
        },
        EStorePhase.POST_LOGIN
      );
      sendPublisherWebhookLoginEvent({
        eventName: ELoginEvents.SHOP_LAND,
        options: {
          playerId: webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerId,
        },
      });
      API.sendShopLand.mutate();
      await API.getPostLoginData.refetch();
    };
    init();
    return () => {
      clearTimeout(fetchInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const keepAlive = async () => {
    if (!webStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
      return logout(utmSource || undefined);
    }

    API.keepAlive.mutate(undefined, {
      onError: () => {
        customEvents.sendCustomEvent(
          EEventsType.LOGOUT,
          {
            reason: 'session ended',
          },
          EStorePhase.PRE_LOGIN
        );
        customEvents.resetSession();
        logout();
      },
      onSuccess: () => {
        customEvents.setDistinctId();
        fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
        if (!isLoggedIn) setIsLoggedIn(true);
      },
    });
  };

  useEffect(() => {
    if (API.keepAlive.isIdle) {
      keepAlive();
    }
  }, [API.keepAlive.isIdle]);

  const openLogoutModal = () => {
    customEvents.sendCustomEvent(EEventsType.PROFILE_MENU_CLICKED, {}, EStorePhase.POST_LOGIN);
    setShowLogoutModal(true);
  };

  const fetchIntervalHandler = () => {
    if (!document.hasFocus()) return;
    fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
  };

  useEffect(() => {
    if (!publisherMetaData.featureFlags?.store_refresh_offers_stale_mode) return;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        API.getOffers.refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [publisherMetaData.featureFlags]);

  useEffect(() => {
    const loadingState = !allImagesLoaded || loadingFromCampaign;
    setDisplayLoader(loadingState);
  }, [allImagesLoaded, loadingFromCampaign]);

  useEffect(() => {
    const isEnabled = Boolean(
      publisherMetaData?.integration.isProfilesEnabled &&
        publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES]
    );
    setIsProfileEnabled(isEnabled);
    if (!isEnabled) webStorageUtil.set(ELocalStorageKeys.INTEGRATION_PORFILE, null);
  }, [
    publisherMetaData.integration.isProfilesEnabled,
    publisherMetaData.featureFlags[EFeatureFlag.INTEGRATION_PROFILES],
  ]);

  const onCollect = async (offerData: OfferData): Promise<void> => {
    const freeOrderData: Partial<CreateOrderPayload> = {
      clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
      paymentProvider: 'free',
      paymentMethod: 'free',
      utmSource: null,
      offerId: offerData.offerId,
      offerJwt: offerData.offerJwt,
      sequence: offerData.indexToCollect,
    };
    try {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_CLICKED,
        {
          offer_id: offerData.offerId,
          offer_name: offerData.offerName,
          platform: getPlatformData(),
          type: ECollectType.OFFER,
        },
        EStorePhase.POST_LOGIN
      );

      const response = await API.createOrder.mutateAsync(freeOrderData);
      const orderId = response?.data?.orderId;

      if (orderId) {
        setActiveOrderId(orderId);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('order creating failed', axiosError);
      if (axiosError.response?.status === 410) {
        setFailedHashValidation(true);
        setDisplayLoader(false);
        return;
      }
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_Id: offerData.offerId,
          offer_name: offerData.offerName,
          status: EOrderStatus.REQUEST_FAILED,
          platform: getPlatformData(),
          type: offerData.offerType,
          reason: axiosError.response?.statusText,
          statusCode: axiosError.response?.status,
        },
        EStorePhase.POST_LOGIN
      );
      navigate('../failed?msg=creating order error');
    }
  };

  const currencyCode = useMemo(() => {
    if (!selectedOffer) return;

    const selectedProduct = getSelectedProduct(selectedOffer, popupIndexToCollect || 0);

    return selectedProduct?.prices?.currencyCode;
  }, [selectedOffer]);

  const selectOffer = (offerData: OfferData) => {
    setDisplayLoader(true);
    setSelectedOffer(offerData);

    const selectedProduct = getSelectedProduct(offerData, popupIndexToCollect || 0);

    const priceData = selectedProduct?.prices;

    if (priceData?.priceInUsdCents === 0) {
      webStorageUtil.set(ELocalStorageKeys.IS_FREE_ORDER_SELECTED, true);
      onCollect(offerData);
    } else {
      webStorageUtil.remove(ELocalStorageKeys.IS_FREE_ORDER_SELECTED);
      setShowPaymentModal(true);
      const products = selectedProduct?.products.map((product) => {
        const { name, quantity } = product;
        return {
          name,
          quantity,
        };
      });
      customEvents.sendCustomEvent(
        EEventsType.OFFER_CLICKED,
        {
          offer_id: offerData.offerId,
          offer_name: offerData.offerName,
          badge_left: getBadgeName('left', offerData?.badges),
          badge_right: getBadgeName('right', offerData?.badges),
          badge_center: getBadgeName('center', offerData?.badges),
          sale_percentage: offerData.productSale?.sale,
          type: offerData.offerType,
          sub_type: offerData?.subType,
          index_to_collect: offerData?.indexToCollect,
          products,
          bundle_price_usd: priceData.priceInUsdCents,
          player_local_currency: priceData.currencyCode,
          bundle_price_total: priceData.priceInUsdCents,
          offer_id_external: offerData.publisherOfferId,
          offer_internal_id: offerData.offerId,
        },
        EStorePhase.POST_LOGIN
      );
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.OFFER,
        data: {
          eventName: EPublisherWebhookOfferEventType.OFFER_CLICKED,
          offers: extractOfferMainDetails([offerData]),
          ...customEvents.getEventsSharedProps(),
        },
      });
    }
  };

  const closePopup = () => {
    setDisplayLoader(false);
    setShowLogoutModal(false);
    setShowPaymentModal(false);
    setActiveOrderId('');
    setCurrentPopupDisplayed(null);
    setSelectedOffer(null);
    setLoadingFromCampaign(false);
    setCampaignOfferId(undefined);
  };

  const getBalancesData = () => {
    const data: IOfferBalance[] = API.getOffers?.data?.balances;
    if (!data) return;
    return data.map((item) => ({
      ...item,
      image: item.images.productPrefix || item.images.product,
      amount: item.quantity,
    }));
  };

  useEffect(() => {
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { params, event } = massageEvent.data;

      if (event === ECheckoutPageEvent.SUPPORT) {
        navigate(params.supportUrl);
        return;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  useEffect(() => {
    API.getOffers.refetch();
  }, [currentLanguage]);

  useEffect(() => {
    if (from === 'campaign' && campaignToken) {
      API.getStoreCampaign.mutate(
        {
          tokenId: campaignToken,
        },
        {
          onSuccess: (data: any) => {
            setCampaignOfferId(data);
          },
        }
      );
    }
  }, [from, campaignToken]);

  const mapBlocksToProps: Record<StoreBodyBlock, Object> = useMemo(
    () => ({
      [StoreBodyBlock.dailyBonus]: {},
      [StoreBodyBlock.playerLevel]: {},
      [StoreBodyBlock.banners]: {},
      [StoreBodyBlock.specialOffers]: { selectOffer },
      [StoreBodyBlock.rollingOffers]: { selectOffer },
      [StoreBodyBlock.bundles]: { selectOffer },
    }),
    [selectOffer]
  );

  // StoreLayout: can be removed
  useEffect(() => {
    const publisherBodyBlocks = publisherMetaData.storeTheme?.storeTemplate?.bodyBlocksOrder;

    // If the publisher body blocks is not defined / empty array, use default
    const arrBlocksOrder: StoreBodyBlock[] =
      publisherBodyBlocks && publisherBodyBlocks.length
        ? publisherBodyBlocks
        : DEFAULT_BODY_BLOCKS_ORDER;

    setBodyBlocksOrder(arrBlocksOrder);
  }, [publisherMetaData.storeTheme?.storeTemplate?.bodyBlocksOrder]);

  useEffect(() => {
    if (publisherMetaData.featureFlags?.store_hide_mobile_background_overlay) {
      let viewportMeta = document.querySelector('meta[name="viewport"]');
      if (!viewportMeta) {
        viewportMeta = document.createElement('meta');
        viewportMeta.setAttribute('name', 'viewport');
        document.head.appendChild(viewportMeta);
      }
      viewportMeta.setAttribute(
        'content',
        'initial-scale=1, width=device-width, height=device-height, viewport-fit=cover, user-scalable=no'
      );
    }
  }, [publisherMetaData]);

  useEffect(() => {
    if (isSSE) {
      queryClient.invalidateQueries([EQueryKeys.OFFERS]);
      API.getOffers.refetch();
      setIsSSE(false);
    }
  }, [isSSE]);

  useSSE({
    enabled: publisherMetaData.featureFlags?.store_sse_support,
    onMessage: async (data: string) => {
      switch (data) {
        case ESSEventType.GET_OFFERS_REQUEST:
          setIsSSE(true);
          break;
        case ESSEventType.KEEPALIVE_PING:
          break;
        default:
          if (process.env.NODE_ENV !== 'production') {
            console.log('Unknown event type:', data);
          }
          break;
      }
    },
    onError: () => {
      logToNewRelic(
        undefined,
        { EVENT: '[SSE] Falling back to polling' },
        true,
        '[SSE] Falling back to polling'
      );
      customEvents.sendCustomEvent(EEventsType.SSE_POLLING_STARTED, {});
      setInterval(async () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('[SSE] Falling back to polling');
        }
        await API.getOffers.refetch();
      }, POLLING_INTERVAL);
    },
  });

  const getTooltipImagesSrc = async (arrIds: string[]): Promise<IProductResponse[]> => {
    try {
      const res = await API.getProductsAssets(arrIds);
      return res;
    } catch (e) {
      console.log('error', e);
      return [];
    }
  };

  const campaignOfferData = useMemo(() => {
    if (!campaignOfferId || API.getOffers?.isLoading || !API.getOffers.data?.offers) return;
    const currentCampaignOffer = API.getOffers?.data?.offers?.promotions?.find(
      (promotion: OfferData) => promotion.offerId === campaignOfferId.offerId
    );
    if (currentCampaignOffer) {
      return extractOfferMainDetails([currentCampaignOffer])[0];
    } else {
      navigate('/failed?msg=offer not found');
    }
  }, [campaignOfferId, API.getOffers?.data]);

  return (
    <div className="storeWrapper">
      <AddToHomePage publisherMetaData={publisherMetaData} customEvents={customEvents} />
      {!(isDesktop || isLandscape) &&
        !publisherMetaData.featureFlags?.store_hide_mobile_background_overlay && (
          <div
            className={'shop__mobile-img'}
            style={{
              backgroundImage: `url(${publisherMetaData.storeTheme.general.backgroundImageMobile})`,
              position: 'fixed',
              zIndex: '-1',
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              maxHeight: '100vh',
            }}
          ></div>
        )}

      {publisherMetaData.featureFlags.store_deeplinks_disable_store && !from && (
        <Stack alignItems="center" justifyContent="center" width="100%" height="100vh">
          <MessageBox
            title={'This link is not valid'}
            text={'Sorry, this link is no longer valid.'}
            fontFamily={publisherMetaData.storeTheme.general.font}
            borderColor={'#FFFFFF'}
          />
        </Stack>
      )}

      {isProfileEnabled && <IntegrationProfilePicker disabled={true} />}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <HeaderWrapper
          renderHeaderV2={publisherMetaData.featureFlags.store_header_version_2}
          showHeader={API.getOffers.data !== undefined}
          balanceQuantityFormat={publisherMetaData.storeTheme.general.balanceQuantityFormat}
          playerName={webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName}
          openLogoutModal={openLogoutModal}
          logoSize={publisherMetaData.storeTheme.general.logoSize}
          balances={getBalancesData()}
          fontFamily={publisherMetaData.storeTheme.general.font}
          profileFrameImg={API.getOffers?.data?.profileFrameImg || ''}
          playerLevelImg={API.getOffers?.data?.playerLevelImg || ''}
          backgroundOpacity={publisherMetaData.storeTheme.storeScreen?.header?.backgroundOpacity}
          showLogo={publisherMetaData.storeTheme.storeScreen?.header?.showLogo}
          showPlayerName={publisherMetaData.storeTheme.storeScreen?.header?.showPlayerName}
          isSticky={publisherMetaData.storeTheme.storeScreen?.header?.isSticky}
        />
      )}

      <PopupsWrapper selectOffer={selectOffer} showPaymentModal={showPaymentModal} />

      <ProductsCollectAnimation
        refetchOffers={API.getOffers.refetch}
        collectedProductsImages={collectedProductsImages}
        setCollectedProductsImages={setCollectedProductsImages}
        shouldDisplayProductsAnimation={shouldDisplayProductsAnimation}
        setIsLoading={setDisplayLoader}
        isDailyBonus={currentPopupDisplayed?.subType === EPopupSubType.DAILY_BONUS}
      />

      {showConfetti && (
        <Lottie
          animationData={animationData}
          loop={false}
          style={{
            position: 'fixed',
            zIndex: 100000,
            top: '50vh',
            left: '50vw',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '50%',
          }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          onComplete={() => {
            setShowConfetti(false);
          }}
          data-testId="storeConfettiAnimation"
        />
      )}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <>
          {publisherMetaData.featureFlags.store_use_new_store_layout ? (
            <StoreLayout
              selectOffer={selectOffer}
              offersData={API.getOffers?.data}
              storeTheme={publisherMetaData.storeTheme}
              hasNoOffers={hasNoOffers}
              translations={{
                subProductsPrefixText: t('bundles.subProductsPrefixText'),
                availability: t('availability'),
                collect: t('collect'),
                noOffers: t('shop.noOffers'),
                noSpecialOffers: t('shop.noSpecialOffers'),
              }}
              DailyBonusWrapper={DailyBonusWrapper}
              tooltipOpenEventHandler={openTooltipEventHandler}
              tooltipLoadCallback={getTooltipImagesSrc}
              refetchOffers={API.getOffers.refetch}
              sendEvent={() => sendEvent}
            />
          ) : (
            // Please update the layout only in the shared UI. The code is duplicated, and we are no longer using the implementation here.
            <div className="offers-wrapper">
              {bodyBlocksOrder.map((key: StoreBodyBlock) => {
                const Block: React.FC = mapBlocksToComponents[key];

                return <Block key={key} {...mapBlocksToProps[key]} />;
              })}

              <MissingOffersBlock />

              {(bundlesInternalViewModel === EBundlesInternalViewModel.BAGELCODE ||
                bundlesInternalViewModel === EBundlesInternalViewModel.BAGELCODE2) &&
                API.getOffers?.data?.playerLevelImg && (
                  <div className="player-level-image">
                    <img src={publisherMetaData.storeTheme.general.logo} alt="logo" />
                    <img alt="Player Level" src={API.getOffers?.data?.playerLevelImg} />
                  </div>
                )}
            </div>
          )}
        </>
      )}
      {showLogoutModal && (
        <LogoutModal
          playerName={webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName}
          closePopup={closePopup}
          showLogoutModal={showLogoutModal}
          logout={logout}
        />
      )}
      {((selectedOffer && showPaymentModal) || campaignOfferData || activeOrderId) && (
        <Checkout
          selectedOffer={
            (campaignOfferData as OfferDataForPublisherEvent) || (selectedOffer as OfferData)
          }
          close={() => closePopup()}
          setLoadingFromCampaign={setLoadingFromCampaign}
          collectId={activeOrderId}
          currencyCode={currencyCode}
        />
      )}
      {failedHashValidation && <HashValidationErrorModal />}
    </div>
  );
};
