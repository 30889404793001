import ReactGA from 'react-ga4';
import LogRocket from 'logrocket';
import useApi from './useApi';
import { isIOS13 } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import { clarity } from 'react-microsoft-clarity';
import { ELocalStorageKeys } from '../constants/enums';
import { webStorageUtil } from 'state/webStorage.state.service';
import { useState } from 'react';

export const CLARITY_ID = 'ji7njsuutu';

export default function useAnalytics() {
  const API = useApi({});
  const [initialized, setInitialized] = useState(false);
  const [mixInitialized, setMixInitialized] = useState(false);
  const mixPanelId = '36bc8102bd15fc0a7471651e632a1c1d';

  type GATracker = {
    get: (fieldName: string) => string | undefined;
  };

  const init = () => {
    const publisherMetaData = API.getPublisherMeta.data;
    if (!mixInitialized) {
      setMixInitialized(true);
      mixpanel.init(mixPanelId, { debug: false });
    }
    if (!webStorageUtil.get(ELocalStorageKeys.ANALYTICS) || initialized) return;
    clarity.init(CLARITY_ID);
    clarity.consent();
    setInitialized(true);
    if (publisherMetaData!.externalServicesConfig.gaActive) {
      ReactGA.initialize(publisherMetaData!.integration.googleAnalyticsMeasurementId, {
        testMode: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
      });
      ReactGA.ga((tracker: GATracker) => {
        localStorage.setItem('ga_client_id', tracker?.get('clientId') || '');
      });
    }
    if (publisherMetaData!.externalServicesConfig.mixpanelActive) {
      // mixpanel.init(mixPanelId, {debug: false});
    }
    if (publisherMetaData!.externalServicesConfig.logRocketActive && isIOS13) {
      LogRocket.init(`${publisherMetaData!.integration.logRocketAppId}/main-ijpwr`);
      LogRocket.identify(mixpanel.get_distinct_id());
    }
  };
  return { init };
}
