export const paymentMethodsDefault = [
  {
    paymentMethodName: 'card',
    paymentMethodDisplayName: 'Credit Card',
    paymentMethodAssetUrl: 'https://media.appcharge.com/defaults/credit-card.svg',
  },
];

export const APPCHARGE_PRIVACY_POLICY_URL = 'https://appcharge.com/privacy-policy/';
export const APPCHARGE_COOKIE_POLICY_URL = 'https://appcharge.com/cookie-policy/';

export const CACHE_TIME_MS = 1000;
export const STALE_TIME_MS = 1000;
export const DEFAULT_SYSTEM_LANGUAGE = 'English';
export const STORE_BACKGROUND_CLASS = 'store-background';
export const LOGIN_TEMPLATE_CLASS = 'login-template';
export const DEFAULT_PROFILE_NAME = 'default';
