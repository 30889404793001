import { useEffect } from 'react';
import useFEMonitoring from './useFEMonitoring';
import useCustomEvents from './useCustomEvents';
import { EEventsType } from 'constants/enums';

interface SSEOptions {
  enabled?: boolean;
  onMessage?: (data: any) => void;
  onError?: (error: any) => void;
}

const SSE_URL = `${process.env.REACT_APP_API_BASE_URL}/store/sse`;

export const useSSE = ({ enabled, onMessage, onError }: SSEOptions) => {
  const { logToNewRelic } = useFEMonitoring();
  const customEvents = useCustomEvents();

  useEffect(() => {
    if (!enabled) return;

    const token = localStorage.getItem('sessionToken');
    if (!token) return;

    let retryCount = 0;
    const MAX_RETRIES = 5;
    const BASE_DELAY = 1000; // Start with 1 second delay

    const connectSSE = () => {
      const trimmedToken = token.slice(1, -1);
      const sse = new EventSource(`${SSE_URL}?t=${trimmedToken}`);

      if (retryCount === 0) {
        logToNewRelic(
          undefined,
          { EVENT: '[SSE] Attempting SSE connection' },
          true,
          '[SSE] Attempting Initial Connection'
        );
        customEvents.sendCustomEvent(EEventsType.SSE_CONNECTION_ATTEMPT, {});
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            '%c[SSE] Attempting SSE connection...',
            'color: blue; font-weight: bold; background-color: #f0f0f0;'
          );
        }
      }

      let isFirstMessage = true;
      sse.onmessage = (event) => {
        if (retryCount > 0) {
          logToNewRelic(
            undefined,
            { EVENT: `[SSE] Connection established successfully after ${retryCount} retries` },
            true,
            `[SSE] Connection established successfully after ${retryCount} retries`
          );
          customEvents.sendCustomEvent(EEventsType.SSE_CONNECTION_ESTABLISHED, {
            retryCount,
          });
        }
        retryCount = 0; // Reset retry count on successful message
        if (isFirstMessage) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              '%c[SSE] Connection established successfully',
              'color: green; font-weight: bold;'
            );
          }
          logToNewRelic(
            undefined,
            { EVENT: '[SSE] Connection established successfully' },
            true,
            '[SSE] Connection established successfully'
          );
          isFirstMessage = false;
          customEvents.sendCustomEvent(EEventsType.SSE_MESSAGE_RECEIVED, {
            message: event.data,
          });
        }
        try {
          logToNewRelic(
            undefined,
            { EVENT: '[SSE] Message received' },
            true,
            `[SSE] Message received: ${event.data}`
          );
          customEvents.sendCustomEvent(EEventsType.SSE_MESSAGE_RECEIVED, {
            message: event.data,
          });
          onMessage?.(event.data);
        } catch (error) {
          logToNewRelic(
            undefined,
            { EVENT: '[SSE] Error parsing SSE message:' },
            true,
            '[SSE] Error parsing SSE message:'
          );
          customEvents.sendCustomEvent(EEventsType.SSE_UNKNOWN_MESSAGE, {
            message: event.data,
            error: (error as any)?.message || 'Unknown error',
          });
          if (process.env.NODE_ENV !== 'production') {
            console.log('[SSE] Error parsing SSE message:' + error);
          }
        }
      };

      sse.onerror = (error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            `%c[SSE] Connection error (attempt ${retryCount + 1}/${MAX_RETRIES})`,
            'color: red;'
          );
        }
        sse.close();

        if (retryCount < MAX_RETRIES) {
          const delay = BASE_DELAY * Math.pow(2, retryCount);
          if (process.env.NODE_ENV !== 'production') {
            console.log(`%c[SSE] Retrying in ${delay / 1000} seconds...`, 'color: yellow;');
          }

          setTimeout(() => {
            retryCount++;
            connectSSE();
          }, delay);
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log('%c[SSE] Max retries reached, giving up', 'color: red; font-weight: bold;');
          }
          logToNewRelic(
            undefined,
            { EVENT: '[SSE] Max retries reached, giving up' },
            true,
            '[SSE] Max retries reached, giving up'
          );
          customEvents.sendCustomEvent(EEventsType.SSE_MAX_RETRIES_REACHED, {});
          onError?.(error);
        }
      };

      return sse;
    };

    const sse = connectSSE();

    return () => {
      sse.close();
    };
  }, [enabled]);

  return {};
};
