import { dailyBonusBadgeProps } from 'components/HeaderWrapper/HeaderWrapper.types';
import { OfferData } from 'constants/apiResponses.types';
import { Observable } from 'state/observable/observable.state.service';

class PopupStateService {
  readonly popupOffers = new Observable<OfferData[]>([]);
  readonly currentPopupDisplayed = new Observable<OfferData | null>(null);
  readonly dailyBonusBadgesNextDate = new Observable<Date | null>();
  readonly dailyBonusBadges = new Observable<dailyBonusBadgeProps[]>([]);
  readonly popupIndexToCollect = new Observable<number>(0);

  clear = () => {
    this.popupOffers.set([]);
    this.currentPopupDisplayed.set(null);
    this.dailyBonusBadgesNextDate.set(null);
    this.dailyBonusBadges.set([]);
    this.popupIndexToCollect.set(0);
  };

  setPopupOffers = (offers: OfferData[]) => this.popupOffers.set(offers);
  setCurrentPopupDisplayed = (current: OfferData | null) => this.currentPopupDisplayed.set(current);
  setDailyBonusBadgesNextDate = (next: Date | null) => this.dailyBonusBadgesNextDate.set(next);
  setDailyBonusBadges = (badges: dailyBonusBadgeProps[]) => this.dailyBonusBadges.set(badges);
  setPopupIndexToCollect = (index: number) => this.popupIndexToCollect.set(index);
}

export const popupStateService = new PopupStateService();
