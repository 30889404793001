import { useObservable } from '../observable';
import { appStateService } from '../app.state.service';
import useApi from 'hooks/useApi';
import { BootResponse, OrderData } from 'constants/apiResponses.types';
import { useMemo } from 'react';
import { EFeatureFlag, EOfferType } from 'constants/enums';

export const useAppState = () => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  const productsAnimationPurchase = useMemo(
    () => publisherMetaData?.featureFlags?.store_animation_after_purchases,
    [publisherMetaData?.featureFlags?.store_animation_after_purchases]
  );

  const productsAnimationCollect = useMemo(
    () =>
      publisherMetaData?.featureFlags[
        EFeatureFlag.STORE_COLLECT_ANIMATION_POST_FREE_COLLECT_PROMOTIONS_POPUPS
      ],
    [
      publisherMetaData?.featureFlags[
        EFeatureFlag.STORE_COLLECT_ANIMATION_POST_FREE_COLLECT_PROMOTIONS_POPUPS
      ],
    ]
  );

  const shouldDisplayProductsAnimation = (order: OrderData): boolean => {
    const price = order.price;
    const isRollingOffer = order.offerType === EOfferType.ROLLING_OFFER;

    return isRollingOffer || (price === 0 ? productsAnimationCollect : productsAnimationPurchase);
  };

  const openInfoModal = (url?: string) => {
    appStateService.setInfoModalUrl(url);
  };

  const closeInfoModal = () => {
    appStateService.setInfoModalUrl();
  };

  return {
    collectedProductsImages: useObservable(appStateService.collectedProductsImages),
    displayLoader: useObservable(appStateService.displayLoader),
    showConfetti: useObservable(appStateService.showConfetti),
    isProfileEnabled: useObservable(appStateService.isProfileEnabled),
    selectedOffer: useObservable(appStateService.selectedOffer),
    failedHashValidation: useObservable(appStateService.failedHashValidation),
    backgroundProps: useObservable(appStateService.backgroundProps),
    infoModalUrl: useObservable(appStateService.infoModalUrl),
    utms: useObservable(appStateService.utms),
    isFromCampaign: useObservable(appStateService.isFromCampaign),
    shouldShowApprovalModal: useObservable(appStateService.shouldShowApprovalModal),
    setCollectedProductsImages: appStateService.setCollectedProductsImages,
    setDisplayLoader: appStateService.setDisplayLoader,
    setShowConfetti: appStateService.setShowConfetti,
    setIsProfileEnabled: appStateService.setIsProfileEnabled,
    setSelectedOffer: appStateService.setSelectedOffer,
    setFailedHashValidation: appStateService.setFailedHashValidation,
    setBackgroundProps: appStateService.setBackgroundProps,
    setUtms: appStateService.setUtms,
    setIsFromCampaign: appStateService.setIsFromCampaign,
    setShouldShowApprovalModal: appStateService.setShouldShowApprovalModal,
    openInfoModal,
    closeInfoModal,
    shouldDisplayProductsAnimation,
  };
};
