import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import { BootResponse, OfferData } from '../../constants/apiResponses.types';
import {
  BagelCodeSpecialOffer,
  LocoBingoSpecialOffer,
  Me2onSpecialOffer,
  PipaSpecialOffer,
  MatchMastersSpecialOffer,
  PokerFaceSpecialOffer,
  BabaSpecialOffer,
  HugeSpecialOffer,
  ProductMadnessSpecialOffer,
  PlaySimpleSpecialOffer,
  GhostSpecialOffer,
  BingoPopSpecialOffer,
} from '@appcharge/shared-ui';
import {
  EBundlesInternalViewModel,
  ESaleDiscountDisplayType,
  ESpecialOfferInternalViewModel,
} from '../../constants/enums';
import { useEvent } from '../../hooks/useEvent';

const templateDesignDic: Record<ESpecialOfferInternalViewModel, any> = {
  [ESpecialOfferInternalViewModel.HUGE]: HugeSpecialOffer,
  [ESpecialOfferInternalViewModel.MATCH_MASTERS]: MatchMastersSpecialOffer,
  [ESpecialOfferInternalViewModel.POKER_FACE]: PokerFaceSpecialOffer,
  [ESpecialOfferInternalViewModel.BAGELCODE]: BagelCodeSpecialOffer,
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: LocoBingoSpecialOffer,
  [ESpecialOfferInternalViewModel.ME2ON]: Me2onSpecialOffer,
  [ESpecialOfferInternalViewModel.PIPA]: PipaSpecialOffer,
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.BABA]: BabaSpecialOffer,
  [ESpecialOfferInternalViewModel.PLAY_SIMPLE]: PlaySimpleSpecialOffer,
  [ESpecialOfferInternalViewModel.GHOST]: GhostSpecialOffer,
  [ESpecialOfferInternalViewModel.BINGO_POP]: BingoPopSpecialOffer,
};
const TIME_LEFT_INTERVAL = 1000;
type TimerState = { [key: string]: string };

interface ISpecialOfferProps {
  selectOffer: () => void;
  data: OfferData;
}

export const SpecialOffer: React.FC<ISpecialOfferProps> = ({ data, selectOffer }) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const borderColor = publisherMetaData.storeTheme.storeScreen.bundleBorderColor;
  const SpecialOfferDesign =
    templateDesignDic[publisherMetaData?.storeTheme.general.specialOffersInternalViewModel];
  const specialOfferRef = useRef<HTMLDivElement>(null);
  const [timers, setTimers] = useState<TimerState>({});
  const [timeLeft, setTimeLeft] = useState<{ [key: string]: number | null }>({});
  const { t } = useTranslation();
  const { openTooltipEventHandler } = useEvent();

  // Format the timeLeft into HH:MM:SS format based on offerId
  useEffect(() => {
    const offerTimeLeft = timeLeft[data.offerId];
    if (!offerTimeLeft || offerTimeLeft < 0) {
      setTimers((prev) => ({ ...prev, [data.offerId]: '' }));
    } else {
      const totalSeconds = Math.floor(offerTimeLeft / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      setTimers((prev) => ({
        ...prev,
        [data.offerId]: `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      }));
    }
  }, [timeLeft[data.offerId]]);

  // Effect to trigger the timer calculation when playerAvailability or schedule changes
  useEffect(() => {
    if (data.playerAvailability?.remaining === 0) {
      setTimers((prev) => ({ ...prev, [data.offerId]: '' }));
      setTimeLeft((prev) => ({ ...prev, [data.offerId]: null }));
    } else if (data?.schedule && !data.schedule.permanent) {
      const endTime = data?.schedule?.timeFrames[0]?.endTime;
      if (endTime) {
        calculateTimeLeft(endTime, data.offerId);
      }
    }
  }, [data]);

  // Calculate time left until the endTime
  const calculateTimeLeft = (endTime: string | Date, offerId: string) => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const endTimeDate = new Date(endTime).getTime();

      const remainingTime = endTimeDate - currentTime;

      setTimeLeft((prev) => ({
        ...prev,
        [offerId]: remainingTime > 0 ? remainingTime : null,
      }));
      if (remainingTime <= 0) {
        clearInterval(interval);
        setTimers((prev) => ({ ...prev, [offerId]: '' }));
        API.getOffers.refetch();
      }
    }, TIME_LEFT_INTERVAL);

    return () => clearInterval(interval);
  };

  return (
    <SpecialOfferDesign
      availability={data.playerAvailability}
      playerAvailability={data.offerPlayerRemainingAvailability}
      backgroundColor={data.offerUi.specialOffer!.backgroundColor}
      title={data.offerUi.specialOffer!.title}
      textColor={data.offerUi.specialOffer!.fontColor}
      fontSize={data.offerUi.specialOffer!.fontSize}
      fontWeight={data.offerUi.specialOffer!.fontWeight}
      subTitle={data.offerUi.specialOffer!.subTitle?.text}
      subTitleColor={data.offerUi.specialOffer!.subTitle?.fontColor}
      subTitleSize={data.offerUi.specialOffer!.subTitle?.fontSize}
      subTitleWeight={data.offerUi.specialOffer!.subTitle?.fontWeight}
      borderColor={borderColor}
      buttonColor={publisherMetaData.storeTheme.storeScreen.buttonColor}
      backgroundImage={data.offerUi.backgroundImage}
      design={
        publisherMetaData.storeTheme.general.bundlesInternalViewModel ===
        EBundlesInternalViewModel.MATCH
          ? EBundlesInternalViewModel.MATCH
          : publisherMetaData.storeTheme.general.bundlesInternalViewModel ===
              EBundlesInternalViewModel.CANDIVORE
            ? EBundlesInternalViewModel.CANDIVORE
            : publisherMetaData.storeTheme.general.specialOffersInternalViewModel
      }
      prices={data.productsSequence[0]?.prices}
      productsList={data.productsSequence[0].products}
      selectOffer={selectOffer}
      buttonTextColor="white"
      badges={data?.badges} // TODO remove old badge schema
      emblemBadges={data?.offerBadges?.emblems}
      ribbonBadges={data?.offerBadges?.ribbons}
      salePercentage={data.productSale?.sale}
      salePercentageDisplayType={
        data.productSale?.type || ('percentage' as ESaleDiscountDisplayType)
      }
      priceDiscount={data.priceDiscount?.discount}
      priceDiscountDisplayType={
        data.priceDiscount?.type || ('percentage' as ESaleDiscountDisplayType)
      }
      isFree={Boolean(!data.productsSequence[0]?.prices?.priceInUsdCents)}
      availabilityText={t('availability')}
      collectText={t('collect')}
      specialOfferRef={specialOfferRef}
      timer={timers[data.offerId] || ''}
      translations={{ includes: t('bundles.subProductsPrefixText') }}
      tooltipOpenEventHandler={openTooltipEventHandler}
    />
  );
};
