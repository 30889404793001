import './style.scss';
import { useNavigate } from 'react-router';
import React, { useEffect, useMemo } from 'react';
import { AcCircularLoader, Overlay, useIsDesktop, useIsLandscape } from '@appcharge/shared-ui';
import CookieConsent from 'components/cookie-consent/cookie-consent';
import useCustomEvents from 'hooks/useCustomEvents';
import { webStorageUtil } from 'state/webStorage.state.service';
import {
  EEventsType,
  EFeatureFlag,
  ELocalStorageKeys,
  ELoginEvents,
  ELoginMethods,
  ELoginMode,
  ESessionStorageKeys,
  EStorePhase,
} from 'constants/enums';
import { Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loginPageService } from './login-page.service';
import { Login } from './Components/Login';
import useLoginEvents from 'hooks/useLoginEvents';
import { useLoginState } from 'state/hooks/login.state.hook';
import { useAppState } from 'state/hooks/app.state.hook';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLoginPage } from './useLoginPage.hook';
import { ProductMadnessLogin } from './Custom/ProductMadness/ProductMadnessLogin';
import useAnalytics from 'hooks/useAnalytics';
import { IntegrationProfilePicker } from './Components/IntegrationProfilePicker';
import { LOGIN_TEMPLATE_CLASS, STORE_BACKGROUND_CLASS } from 'constants/defaults';
import { StyledConsentOverlay } from './style';
import useIndexedDB from 'hooks/useIndexedDB';
import { jwtDecode } from 'jwt-decode';
import { JWTObject } from '../../constants/apiResponses.types';

const JWT_PARAM = 'acjwt';

export const LoginTemplate: React.FC = () => {
  const { API, publisherMetaData, isProductMadness } = useLoginPage();

  const { init } = useAnalytics();
  const customEvents = useCustomEvents();
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();

  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const jwtParam = urlParams.get(JWT_PARAM);
  const { setDisplayLoader, isProfileEnabled, setIsProfileEnabled, setShouldShowApprovalModal } =
    useAppState();

  const {
    proofKey,
    loggingWithProofKey,
    showCookiesOverlay,
    hasConsent,
    shouldShowTermsAlert,
    setShouldShowTermsAlert,
    setIsTermsAlertShaking,
    setShowCookiesOverlay,
    setOtpToken,
    navigateToMode,
    setProofKey,
    clear,
  } = useLoginState();
  const { getAllValues } = useIndexedDB(['sessions']);
  const { data: sessionData, isLoading: isSessionDataLoading } = getAllValues('sessions');
  const { processSuccessfulLogin } = useLoginState();

  useEffect(() => {
    setShowCookiesOverlay(webStorageUtil.get(ELocalStorageKeys.ANALYTICS) === null);
  }, []);

  const onLoginLand = async () => {
    if (jwtParam) {
      const sessionData = webStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
      try {
        const { data } = await API.temporaryLogin.mutateAsync({
          sessionId: sessionData.id,
          jwt: jwtParam,
          publisherId: publisherMetaData.storeTheme.publisherId,
        });
        if (data) {
          await processSuccessfulLogin({ authMethod: ELoginMethods.DEEP_LINK }, data);
          setShouldShowApprovalModal(true);
        } else {
          setShouldShowApprovalModal(false);
        }
        const { d10xPayload, originalLink }: JWTObject = jwtDecode(jwtParam);
        const checkoutToken = originalLink.split('/').pop() || '';
        navigate(`/shop?from=campaign&token=${checkoutToken}`);
        if (d10xPayload) {
          sessionStorage.setItem('dynamoPayload', d10xPayload);
        }
      } catch (error) {
        customEvents.sendCustomEvent(
          EEventsType.ERROR,
          {
            type: 'api error',
            route: 'temporaryLogin',
            error: JSON.stringify(error),
          },
          EStorePhase.PRE_LOGIN
        );
        navigate('../failed?error=auth?msg=Offer is not available');
        throw error;
      }
      return;
    }
    if (!isSessionDataLoading && sessionData && sessionData.length > 0) {
      navigate(`/shop`);
    } else {
      sendPublisherWebhookLoginEvent({ eventName: ELoginEvents.LOGIN_LAND });
      if (!loginPageService.isWebviewBrowser()) {
        customEvents.sendCustomEvent(
          EEventsType.LOGIN_LAND,
          loginPageService.getLoginEventsData({
            eventSharedProps: customEvents.getEventsSharedProps(),
          }),
          EStorePhase.PRE_LOGIN
        );
      }
      if (webStorageUtil.get(ELocalStorageKeys.PUBLISHER_META) === null) {
        await API.getPublisherMeta.refetch();
      }
    }
  };

  useEffect(() => {
    onLoginLand();
  }, []);

  useEffect(() => {
    if (Boolean(proofKey) || jwtParam) {
      setDisplayLoader(true);
    }
  }, [proofKey, jwtParam]);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) webStorageUtil.set(ESessionStorageKeys.SOURCE, 'home screen bookmark');
  }, [location]);

  useEffect(() => {
    init();
  }, [showCookiesOverlay, publisherMetaData]);

  // prevent back-forward cache
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    return () => {
      window.onpageshow = null;
    };
  }, []);

  // Add boot vars to CSS VARS
  useEffect(() => {
    const buttonTextColor = publisherMetaData.storeTheme.storeScreen?.buttonTextColor;
    const root = document.documentElement;
    root.style.setProperty('--button-text-color', buttonTextColor);
  }, [publisherMetaData.storeTheme.storeScreen]);

  useEffect(() => {
    const checkURLforProofKey = () => {
      const proofKeyParam = urlParams.get('proofKey');
      const tokenKeyParam = urlParams.get('token');

      if (proofKeyParam && publisherMetaData) {
        setProofKey(proofKeyParam);
        navigateToMode(ELoginMode.OTP);
      }
      if (tokenKeyParam && publisherMetaData) {
        setOtpToken(tokenKeyParam);
      }
    };

    checkURLforProofKey();
  }, [publisherMetaData]);

  useEffect(() => {
    return function cleanup() {
      clear();
    };
  }, []);

  useEffect(() => {
    const isEnabled = Boolean(
      publisherMetaData?.integration.isProfilesEnabled &&
        publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES]
    );
    setIsProfileEnabled(isEnabled);
    if (!isEnabled) webStorageUtil.set(ELocalStorageKeys.INTEGRATION_PORFILE, null);
  }, [
    publisherMetaData.integration.isProfilesEnabled,
    publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES],
  ]);

  const googleAppId = useMemo(
    () => publisherMetaData?.integration?.playersAuthentication?.googleAppId || '',
    [publisherMetaData?.integration?.playersAuthentication?.googleAppId]
  );

  const consentRequired = useMemo(
    () => publisherMetaData?.storeTheme?.login?.consentSection?.consentRequired,
    [publisherMetaData?.storeTheme?.login?.consentSection?.consentRequired]
  );

  const showConsentOverlay = useMemo(
    () => consentRequired && !hasConsent && !showCookiesOverlay,
    [consentRequired, hasConsent, showCookiesOverlay]
  );

  const loginTemplateHeight = document.querySelector(`.${STORE_BACKGROUND_CLASS}`)?.scrollHeight;

  const handleClickOnLoginButton = () => {
    if (!hasConsent) {
      document
        .querySelector(`.${STORE_BACKGROUND_CLASS}`)
        ?.scrollIntoView({ block: 'start', behavior: 'smooth' });

      if (shouldShowTermsAlert) {
        setIsTermsAlertShaking(true);
        setTimeout(() => setIsTermsAlertShaking(false), 500);

        return;
      }
      setShouldShowTermsAlert(true);
    }
  };

  if (loggingWithProofKey) {
    return (
      <Overlay overlayPercentage={80} zIndex={10000}>
        <AcCircularLoader text={t('processing')} />
      </Overlay>
    );
  }
  return (
    <div className={LOGIN_TEMPLATE_CLASS}>
      {!jwtParam ? (
        <Stack
          display={isProductMadness && (isDesktop || isLandscape) ? 'block' : 'flex'}
          justifyContent={isMobile ? 'flex-start' : 'center'}
          alignItems="center"
          flex={1}
        >
          <GoogleOAuthProvider clientId={googleAppId}>
            {isProductMadness ? <ProductMadnessLogin /> : <Login />}
          </GoogleOAuthProvider>
        </Stack>
      ) : (
        <Stack width="100%" justifyContent="center" alignItems="center" flex={1}></Stack>
      )}
      {showConsentOverlay && (
        <StyledConsentOverlay
          className={'consent-overlay'}
          onClick={handleClickOnLoginButton}
          hasConsent={hasConsent}
          height={loginTemplateHeight}
        />
      )}
      {showCookiesOverlay && <CookieConsent />}
      {isProfileEnabled && <IntegrationProfilePicker />}
    </div>
  );
};
