import { HeartOfVegasPlayerLevel, ProductMadnessValueBlock } from '@appcharge/shared-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { EBundlesInternalViewModel } from '../../../constants/enums';
import { BootResponse } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';

const jackpotSlotsBackgroundImage = 'https://media.appcharge.com/media/shop-images/JMS-banner.png';
const jackpotSlotsDesktopLogo =
  'https://media.appcharge.com/media/shop-images/PJS--welcome-logo-desktop.png';
const bigFishBackgroundImage = 'https://media.appcharge.com/media/shop-images/BFC-banner.png';
const bigFishDesktopLogo =
  'https://media.appcharge.com/media/shop-images/BFC--welcome-logo-desktop.png';

const mapPlayerLevelToTemplate: Partial<Record<EBundlesInternalViewModel, any>> = {
  [EBundlesInternalViewModel.HEART_OF_VEGAS]: HeartOfVegasPlayerLevel,
  [EBundlesInternalViewModel.JACKPOT_SLOTS]: ProductMadnessValueBlock,
  [EBundlesInternalViewModel.BIG_FISH_CASINO]: ProductMadnessValueBlock,
};

export const PlayerLevelWrapper: React.FC = () => {
  const [playerLevel, setPlayerLevel] = useState<any>(null);

  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;

  useEffect(() => {
    if (!API.getOffers.data?.offers || !API.getOffers.data?.playerLevel) return;

    setPlayerLevel(API.getOffers.data.playerLevel);
  }, [API.getOffers.data?.offers, API.getOffers.data?.playerLevel]);

  const mapTemplateToProps: Partial<Record<EBundlesInternalViewModel, Object>> = useMemo(
    () => ({
      [EBundlesInternalViewModel.HEART_OF_VEGAS]: {
        assetUrl: playerLevel?.assetUrl,
        text: playerLevel?.text,
        endsIn: playerLevel?.endsIn,
        onTimerDoneHandler: () => {
          API.getOffers.refetch();
        },
      },
      [EBundlesInternalViewModel.JACKPOT_SLOTS]: {
        mobileLogoImage: publisherMetaData.storeTheme.general.logo,
        desktopLogoImage: jackpotSlotsDesktopLogo,
        backgroundImage: jackpotSlotsBackgroundImage,
        playerLevelImage: API.getOffers?.data?.playerLevelImg,
        storeSaleImage: publisherMetaData.storeTheme.general?.storeSaleImage,
      },
      [EBundlesInternalViewModel.BIG_FISH_CASINO]: {
        mobileLogoImage: publisherMetaData.storeTheme.general.logo,
        desktopLogoImage: bigFishDesktopLogo,
        backgroundImage: bigFishBackgroundImage,
        playerLevelImage: API.getOffers?.data?.playerLevelImg,
        storeSaleImage: publisherMetaData.storeTheme.general?.storeSaleImage,
      },
    }),
    [playerLevel, API.getOffers?.data?.playerLevelImg, publisherMetaData?.storeTheme]
  );

  const PlayerLevel = useMemo(
    () => mapPlayerLevelToTemplate[bundlesInternalViewModel],
    [bundlesInternalViewModel]
  );

  if (
    !PlayerLevel ||
    (bundlesInternalViewModel === EBundlesInternalViewModel.HEART_OF_VEGAS && !playerLevel)
  )
    return <></>;

  return <PlayerLevel {...mapTemplateToProps[bundlesInternalViewModel]} />;
};
