import { useEffect } from 'react';
import { OfferData } from '../constants/apiResponses.types';
import { useEventBatchContext } from './useEventBatch';

const observedItems = [] as string[];

export const useObserver = (element: HTMLElement | null, data: OfferData) => {
  const { sendEvent } = useEventBatchContext();

  useEffect(() => {
    observerElement(element, data, sendEvent);
  }, [element]);
};

export const observerElement = (
  element: HTMLElement | null,
  data: OfferData,
  sendEvent: (event: OfferData) => void
) => {
  if (!element) return;
  const options = {
    threshold: 1.0,
  };
  const observer = new IntersectionObserver((entry, observer) => {
    if (!entry[0].isIntersecting) return;
    if (!observedItems.includes(data.offerId)) {
      observedItems.push(data.offerId);
      sendEvent(data);
    }
    observer.disconnect();
  }, options);
  observer.observe(element);
};
