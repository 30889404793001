import { LoginButton } from '@appcharge/shared-ui';
import { useLoginPage } from '../useLoginPage.hook';
import AppleLogin from 'react-apple-login';
import { EAppleResponseType, ELoginMethods, ESessionStorageKeys } from 'constants/enums';
import { useTranslation } from 'react-i18next';
import useLoginEvents from 'hooks/useLoginEvents';
import { useEffect } from 'react';
import { useAppState } from 'state/hooks/app.state.hook';
import useUrlQuery from 'hooks/useUrlQuery';
import useUrlHash from 'hooks/useUrlHash';
import { useLoginState } from 'state/hooks/login.state.hook';
import { webStorageUtil } from 'state/webStorage.state.service';

export const AppleLoginButton: React.FC = () => {
  const { publisherMetaData } = useLoginPage();
  const { recordLoginStartTime } = useLoginEvents();
  const { setDisplayLoader } = useAppState();
  const { hasConsent, loginResponse, loginButtonClickWrapper } = useLoginState();
  const { t } = useTranslation();
  const isAppleRedirect = useUrlQuery('apple') === 'true';
  const appleIdToken = useUrlHash('id_token');
  const playerAuthData = publisherMetaData.integration.playersAuthentication;

  const appleResponseHandler = (response: any) => {
    const token = response.code || response;
    if (token) {
      const data = {
        userToken: token,
        authMethod: ELoginMethods.APPLE,
      };
      webStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
      loginResponse(data);
    }
  };

  useEffect(() => {
    if (isAppleRedirect) setDisplayLoader(true);
    if (appleIdToken) appleResponseHandler(appleIdToken);
  }, [isAppleRedirect, appleIdToken]);

  return (
    <AppleLogin
      clientId={playerAuthData.appleAppId}
      redirectURI={`${window.location.href}?apple=true`}
      responseType={
        playerAuthData.appleResponseType === EAppleResponseType.CODE
          ? EAppleResponseType.CODE
          : EAppleResponseType.ID_TOKEN
      }
      responseMode={
        playerAuthData.appleResponseType === EAppleResponseType.CODE ? 'query' : 'fragment'
      }
      callback={appleResponseHandler}
      render={(renderProps) => (
        <LoginButton
          variant="apple"
          callback={() => {
            recordLoginStartTime();
            loginButtonClickWrapper(renderProps.onClick, ELoginMethods.APPLE);
          }}
          text={t('login.signInWithApple')}
          dataTestId="apple-login-button"
          isDisabled={!hasConsent}
          opacityOnDisabled={false}
        >
          <img src="https://media.appcharge.com/defaults/apple_icon_white.svg" alt="apple icon" />
        </LoginButton>
      )}
    />
  );
};
