import { useObservable } from 'state/observable/observable.state.hook';
import { popupStateService } from 'state/popup.state.service';

export const usePopupState = () => {
  return {
    popupOffers: useObservable(popupStateService.popupOffers),
    currentPopupDisplayed: useObservable(popupStateService.currentPopupDisplayed),
    dailyBonusBadgesNextDate: useObservable(popupStateService.dailyBonusBadgesNextDate),
    dailyBonusBadges: useObservable(popupStateService.dailyBonusBadges),
    popupIndexToCollect: useObservable(popupStateService.popupIndexToCollect),
    setPopupOffers: popupStateService.setPopupOffers,
    setCurrentPopupDisplayed: popupStateService.setCurrentPopupDisplayed,
    setDailyBonusBadgesNextDate: popupStateService.setDailyBonusBadgesNextDate,
    setDailyBonusBadges: popupStateService.setDailyBonusBadges,
    setPopupIndexToCollect: popupStateService.setPopupIndexToCollect,
    clear: popupStateService.clear,
  };
};
