import { SupportedLanguage } from 'constants/apiRequest.types';
import { DEFAULT_SYSTEM_LANGUAGE } from 'constants/defaults';
import { ELocalStorageKeys } from 'constants/enums';
import { Observable } from 'state/observable/observable.state.service';
import { webStorageUtil } from 'state/webStorage.state.service';

class LocalizationStateService {
  readonly currentLanguage = new Observable<string>(
    webStorageUtil.get(ELocalStorageKeys.CURRENT_LANGUAGE) || DEFAULT_SYSTEM_LANGUAGE
  );
  readonly languagesList = new Observable<SupportedLanguage[]>([]);
  readonly isI18nReady = new Observable<boolean>(true);

  clear = () => {
    this.currentLanguage.set('');
    this.languagesList.set([]);
  };
  setCurrentLanguage = (currentLanguage: string) => this.currentLanguage.set(currentLanguage);
  setLanguagesList = (lngList: SupportedLanguage[]) => this.languagesList.set(lngList);
  setIsI18nReady = (isReady: boolean) => this.isI18nReady.set(isReady);
}
export const localizationStateService = new LocalizationStateService();
