import { BootResponse, OfferData } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';
import {
  ActionButton,
  DailyBonus,
  Gradient,
  PostPurchase,
  ProductData,
} from '@appcharge/shared-ui';
import { useEffect, useState } from 'react';
import useCustomEvents from 'hooks/useCustomEvents';
import {
  ECollectType,
  EEventsType,
  EPopupSubType,
  EResultOptions,
  ESaleDiscountDisplayType,
  EStorePhase,
  EOrderStatus,
} from 'constants/enums';
import './style.scss';
import { createGradientOrSolid, getPlatformData, OrderPopupEvents } from 'utils';
import { useTranslation } from 'react-i18next';
import { CreateOrderPayload } from 'constants/apiRequest.types';
import { AxiosError } from 'axios';
import { useAppState } from 'state/hooks/app.state.hook';
import { usePopupState } from 'state/hooks/popup.state.hook';

interface IPopUpsHandlerProps {
  onPurchaseClick: (offerData: OfferData) => void;
  assignNextPopUp: () => void;
  setClickedClosePopups: any;
  nextAvailableDate: Date;
  dailyBonusRef: React.RefObject<OrderPopupEvents>;
  postPurchaseRef: React.RefObject<OrderPopupEvents>;
}

export const PopUpsHandler: React.FC<IPopUpsHandlerProps> = ({
  onPurchaseClick,
  assignNextPopUp,
  setClickedClosePopups,
  nextAvailableDate,
  dailyBonusRef,
  postPurchaseRef,
}) => {
  const [orderState, setOrderState] = useState<string>();
  const [activeOrderId, setActiveOrderId] = useState<string>();
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [nextProducts, setNextProducts] = useState<ProductData[] | null>(null);
  const API = useApi({ orderId: activeOrderId });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const { t } = useTranslation();
  const {
    setDisplayLoader,
    setShowConfetti,
    showConfetti,
    setFailedHashValidation,
    setCollectedProductsImages,
    shouldDisplayProductsAnimation,
  } = useAppState();
  const {
    popupIndexToCollect,
    currentPopupDisplayed,
    setCurrentPopupDisplayed,
  } = usePopupState();

  // refetch order status when activeOrderId changes
  useEffect(() => {
    if (activeOrderId) {
      API.getOrder.refetch().catch((err) => console.error(err));
    }
  }, [activeOrderId]);

  // check if order status changed
  useEffect(() => {
    if (!activeOrderId || !API.getOrder.data?.state) return;
    if (API.getOrder?.data?.state !== orderState) {
      setOrderState(API.getOrder.data?.state);
    }
  }, [API.getOrder?.data?.state, orderState, activeOrderId]);

  useEffect(() => {
    if (!activeOrderId) return;
    if (
      orderState === EOrderStatus.CHARGE_SUCCEED ||
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: currentPopupDisplayed?.offerId,
          offer_name: currentPopupDisplayed?.offerName,
          result:
            orderState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          platform: getPlatformData(),
          type: ECollectType.POPUP,
          sub_type: currentPopupDisplayed?.subType,
          reason: orderState !== EOrderStatus.CHARGE_SUCCEED ? API.getOrder.data.reason : undefined,
          index: currentPopupDisplayed?.indexToCollect,
        },
        EStorePhase.POST_LOGIN
      );
      setDisplayLoader(false);
      setActiveOrderId(undefined);
      if (orderState === EOrderStatus.CHARGE_SUCCEED) {
        const offer = API.getOrder.data?.offer;

        if (offer && shouldDisplayProductsAnimation(offer)) {
          const collectedProductsImages: string[] = offer?.products.map(
            ({ image }: { image: string }) => image
          );
          setCollectedProductsImages(collectedProductsImages);
        } else {
          setShowConfetti(true);
        }
        if (currentPopupDisplayed?.subType === EPopupSubType.DAILY_BONUS)
          dailyBonusRef?.current?.onOrderSuccess();
        else postPurchaseRef?.current?.onOrderSuccess();
        if (!nextProducts) {
          setCurrentPopupDisplayed(null);
        }
      } else setShowFailPopup(true);
    } else {
      const timer = setTimeout(() => {
        API.getOrder.refetch().catch((err) => console.error(err));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [orderState, API.getOrder.isRefetching]);

  useEffect(() => {
    if (showConfetti && !nextProducts) {
      API.getOffers.refetch();
    }
  }, [showConfetti])

  useEffect(() => {
    if (currentPopupDisplayed?.subType === EPopupSubType.DAILY_BONUS) {
      const { productsSequence, startOver, isBadgeNextProduct } = currentPopupDisplayed;
      const isSingleProduct = productsSequence?.length === 1;
      const nextProductExists =
        productsSequence[(popupIndexToCollect || 0) + 1]?.products?.length > 0;

      // If there is only one product, show that product
      if (startOver && isSingleProduct) {
        setNextProducts(productsSequence[0]?.products);
        return;
      } else setNextProducts(null);

      // If next product is linked to a badge click, show next badge product
      if (isBadgeNextProduct) {
        setNextProducts(productsSequence[popupIndexToCollect || 0]?.products);
      } else {
        if (nextProductExists) {
          setNextProducts(productsSequence[(popupIndexToCollect || 0) + 1]?.products);
        } else if (startOver) {
          setNextProducts(productsSequence[0]?.products); // Restart from beginning
        }
      }
    }
  }, [currentPopupDisplayed]);

  const onCollect = async (item: OfferData): Promise<void> => {
    setOrderState(undefined);
    setDisplayLoader(true);
    const freeOrderData: Partial<CreateOrderPayload> = {
      clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
      paymentProvider: 'free',
      paymentMethod: 'free',
      offerJwt: item.offerJwt,
      offerId: item.offerId,
    };
    try {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_CLICKED,
        {
          offer_Id: item.offerId,
          offer_name: item.offerName,
          status: orderState,
          platform: getPlatformData(),
          type: ECollectType.POPUP,
          sub_type: item.subType,
          index: item.indexToCollect,
        },
        EStorePhase.POST_LOGIN
      );
      customEvents.sendCustomEvent(EEventsType.POP_UP_CLOSED, {
        offer_id: item.offerId,
        sub_type: item.subType,
        reason: 'collect',
        index: item.indexToCollect,
      });
      const response = await API.createOrder.mutateAsync(freeOrderData);
      const orderId = response?.data?.orderId;

      if (orderId) {
        setActiveOrderId(orderId);
      } else {
        setActiveOrderId(undefined);
        setShowFailPopup(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('order creating failed', axiosError);
      if (axiosError.response?.status === 410) {
        setFailedHashValidation(true);
        setDisplayLoader(false);
        return;
      }
      setActiveOrderId(undefined);
      setDisplayLoader(false);
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_Id: item.offerId,
          offer_name: item.offerName,
          status: EOrderStatus.REQUEST_FAILED,
          statusCode: axiosError.response?.status,
          platform: getPlatformData(),
          type: ECollectType.POPUP,
          sub_type: item.subType,
          index: item.indexToCollect,
          reason: axiosError.response?.statusText,
        },
        EStorePhase.POST_LOGIN
      );
      setShowFailPopup(true);
    }
  };

  const onClose = async (
    isShowingNextProduct: boolean,
    offerId: string,
    currentProdSeqIndex = 1,
    subType: string
  ) => {
    setDisplayLoader(true);
    setActiveOrderId(undefined);
    setOrderState(undefined);
    setCurrentPopupDisplayed(null);
    setClickedClosePopups((prevState: string[]) => [...prevState, offerId]);
    try {
      if (!isShowingNextProduct) {
        await API.sendOrderImpression({
          offerId,
          productSequenceIndex: currentProdSeqIndex,
        });
        customEvents.sendCustomEvent(EEventsType.POP_UP_CLOSED, {
          offerId,
          reason: 'close',
          sub_type: subType,
        });
      } else {
        setTimeout(assignNextPopUp, 100);
      }
    } catch (err) {
      console.error('Error in sendOrderImpression:', err);
    } finally {
      API.getOffers.refetch().then(()=> setDisplayLoader(false));
    }
  };

  const onTryAgain = () => {
    setShowFailPopup(false);
    window.location.reload();
  };

  const onAnimationSuccessEndNoPreview = () => {
    if (currentPopupDisplayed) {
      setClickedClosePopups((prevState: any) => [...prevState, currentPopupDisplayed?.offerId]);
    }
    assignNextPopUp();
  };

  const onCollectPostPurchase = () => {
    if (!currentPopupDisplayed) return;
    setDisplayLoader(true);
    const isFree =
      !currentPopupDisplayed.productsSequence[popupIndexToCollect || 0].prices.priceInUsdCents;
    if (isFree) {
      onCollect(currentPopupDisplayed);
    } else onPurchaseClick(currentPopupDisplayed);
  };

  return (
    <>
      {currentPopupDisplayed && currentPopupDisplayed.subType === EPopupSubType.DAILY_BONUS && (
        <DailyBonus
          key={currentPopupDisplayed.offerId}
          ref={dailyBonusRef}
          title={currentPopupDisplayed?.offerUi?.specialOffer?.title || ''}
          titleSize={currentPopupDisplayed?.offerUi?.specialOffer?.fontSize + 'px'}
          titleWeight={currentPopupDisplayed?.offerUi?.specialOffer?.fontWeight}
          titleColor={
            currentPopupDisplayed?.offerUi?.specialOffer?.fontColor
              ? createGradientOrSolid(
                  currentPopupDisplayed.offerUi.specialOffer?.fontColor as Gradient
                )
              : 'white'
          }
          borderWidth={currentPopupDisplayed?.offerUi?.borderWidth}
          borderColor={currentPopupDisplayed?.offerUi?.borderColor}
          products={currentPopupDisplayed?.productsSequence[popupIndexToCollect || 0]?.products}
          onCollect={() => onCollect(currentPopupDisplayed)}
          onClose={({ isShowingNextProduct }: { isShowingNextProduct: boolean }) =>
            onClose(
              isShowingNextProduct,
              currentPopupDisplayed.offerId,
              currentPopupDisplayed?.productsSequence[popupIndexToCollect || 0].index,
              currentPopupDisplayed.subType || ''
            )
          }
          backgroundImage={currentPopupDisplayed?.offerUi?.backgroundImage}
          buttonText={t('collect')}
          nextProducts={nextProducts}
          nextAvailableCollectUTC={nextAvailableDate}
          showEvery={currentPopupDisplayed?.showEvery}
          onAnimationSuccessEndNoPreview={onAnimationSuccessEndNoPreview}
          showCloseButton={publisherMetaData.featureFlags?.store_present_daily_bonus_x_icon}
          isBadgeNextProduct={currentPopupDisplayed?.isBadgeNextProduct}
          shouldDisplayProductsAnimation={shouldDisplayProductsAnimation}
          dataTestId={currentPopupDisplayed.offerId}
        />
      )}
      {currentPopupDisplayed &&
        currentPopupDisplayed.subType === EPopupSubType.POST_PURCHASE && (
          <PostPurchase
            ref={postPurchaseRef}
            key={currentPopupDisplayed.offerId}
            title={currentPopupDisplayed.offerUi.specialOffer?.title || ''}
            titleColor={
              currentPopupDisplayed.offerUi.specialOffer?.fontColor
                ? createGradientOrSolid(
                    currentPopupDisplayed.offerUi.specialOffer?.fontColor as Gradient
                  )
                : 'white'
            }
            titleWeight={currentPopupDisplayed.offerUi.specialOffer?.fontWeight}
            titleSize={currentPopupDisplayed.offerUi.specialOffer?.fontSize + 'px'}
            backgroundColor={currentPopupDisplayed.offerUi?.specialOffer?.backgroundColor?.colorOne}
            backgroundImage={currentPopupDisplayed.offerUi.backgroundImage}
            borderColor={currentPopupDisplayed?.offerUi?.borderColor}
            borderWidth={currentPopupDisplayed.offerUi.borderWidth}
            priceDiscount={currentPopupDisplayed.priceDiscount?.discount}
            priceDiscountDisplayType={
              currentPopupDisplayed.priceDiscount?.type ||
              ('percentage' as ESaleDiscountDisplayType)
            }
            buttonText={t('collect')}
            products={currentPopupDisplayed.productsSequence[popupIndexToCollect || 0]?.products}
            onActionBtnClicked={onCollectPostPurchase}
            showCloseButton={true}
            onClose={({ isShowingNextProduct }: { isShowingNextProduct: boolean }) =>
              onClose(
                isShowingNextProduct,
                currentPopupDisplayed.offerId,
                currentPopupDisplayed.productsSequence[popupIndexToCollect || 0]?.index,
                currentPopupDisplayed.subType || ''
              )
            }
            prices={currentPopupDisplayed.productsSequence[popupIndexToCollect || 0]?.prices}
            dataTestId={currentPopupDisplayed.offerId}
          />
        )}
      {showFailPopup && (
        <div className={'popup-handler__fail-screen'}>
          <h2>{t('failed.somethingWentWrong')}</h2>
          <ActionButton
            className={'popup-handler__fail-screen__action-button'}
            borderRadius={'7px'}
            text={'Try Again'}
            action={onTryAgain}
          ></ActionButton>
        </div>
      )}
    </>
  );
};
