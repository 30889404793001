import React, { createContext, useEffect, useRef } from 'react';

import useApi from '../../hooks/useApi';
import { extractOfferMainDetails } from '../../utils';
import {
  EEventsType,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EStorePhase,
} from '../../constants/enums';
import { OfferEventData } from '../../constants/apiRequest.types';
import { OfferData } from '../../constants/apiResponses.types';
import useCustomEvents from 'hooks/useCustomEvents';
import { ProductData } from '@appcharge/shared-ui';

interface EventContextType {
  sendEvent: (event: OfferData) => void;
}

export const EventBatchContext = createContext<EventContextType | undefined>(undefined);

export const EventBatchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const eventsRef = useRef<OfferData[]>([]);
  const customEvents = useCustomEvents();
  const API = useApi({});
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const debounceDelay = 1000;

  const arrangeMixpanelData = (data: OfferData[]) => {
    return data.map((offer: OfferData) => ({
      offer_id: offer.offerId,
      external_id: offer.publisherOfferId,
      name: offer.offerName,
      sale_percentage: offer.productSale?.sale,
      type: offer.offerType,
      index_to_collect: offer?.indexToCollect,
      sub_type: offer?.subType,
      start_date: offer.schedule?.timeFrames[0]?.startTime,
      end_date: offer.schedule?.timeFrames[0]?.endTime,
      products: offer.productsSequence[
        offer.indexToCollect ? offer.indexToCollect - 1 : 0
      ].products.map((product: ProductData) => ({
        product_id: product.publisherProductId,
        product_name: product.name,
        product_type: product.productType,
        product_quantity: product.quantity,
      })),
      priceInUsdCents:
        offer.productsSequence[offer.indexToCollect ? offer.indexToCollect - 1 : 0].prices
          .priceInUsdCents,
    }));
  };

  const sendEvent = (data: OfferData) => {
    eventsRef.current.push(data);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      if (eventsRef.current.length > 0) {
        customEvents.sendCustomEvent(
          EEventsType.OFFERS_SHOWN,
          {
            offers: arrangeMixpanelData(eventsRef.current),
          },
          EStorePhase.POST_LOGIN
        );
        API.sendPublisherWebhookEvent.mutate({
          eventType: EPublisherWebhookEventType.OFFER,
          data: {
            eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
            offers: extractOfferMainDetails(eventsRef.current),
            ...customEvents.getEventsSharedProps(),
          } as OfferEventData,
        });
        eventsRef.current = [];
      }
    }, debounceDelay);
  };

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return <EventBatchContext.Provider value={{ sendEvent }}>{children}</EventBatchContext.Provider>;
};
