import styled, { css } from 'styled-components';

export const StyledCheckoutWrapper = styled.div<{ shouldHide: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100lvh;
  width: 100vw;
  z-index: 1500;

  ${({ shouldHide }) =>
    shouldHide &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;
