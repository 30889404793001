import { useQuery, useMutation, useQueryClient } from "react-query";
import { openDB } from "idb";

const openDatabase = async (tableNames: string[]) => {
  return openDB("storeDB", 1, {
    upgrade(db) {
      tableNames.forEach((tableName) => {
        if (!db.objectStoreNames.contains(tableName)) {
          db.createObjectStore(tableName, {
            autoIncrement: true,
            keyPath: "id",
          });
        }
      });
    },
  });
};

const useIndexedDB = (
    tableNames: string[]
) => {
  const queryClient = useQueryClient();
  const fetchAllValues = async (tableName: string) => {
     const db = await openDatabase(tableNames);
     return db.getAll(tableName);
  };

  const getAllValues = (tableName: string) => {
     return useQuery([tableName, "all"], () => fetchAllValues(tableName));
  };

  const addValue = useMutation(
   async (params: { tableName: string; value: object }) => {
     const db = await openDatabase(tableNames);
     const transaction = db.transaction(params.tableName, "readwrite");
     const store = transaction.objectStore(params.tableName);
     return await store.put(params.value);
    },
      {
        onSuccess: async (data, variables) => {
          await queryClient.invalidateQueries([variables.tableName, "all"]);
     },
      onError: (error) => {
        console.error("Error inserting/updating data:", error);
      },
    }
  );

  const updateValue = useMutation(
    async (params: { tableName: string; id: number; newItem: any }) => {
      const db = await openDatabase(tableNames);
      const store = db.transaction(params.tableName, "readwrite").objectStore(
        params.tableName
      );
      const existingItem = await store.get(params.id);
      const updatedItem = { ...existingItem, ...params.newItem };
      await store.put(updatedItem, params.id);
      return updatedItem;
    },
      {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([variables.tableName, "all"]);
      },
      onError: (error) => {
        console.error("Error updating item:", error);
      },
    }
  );

  const deleteValue = useMutation(
    async (params: { tableName: string; id: number }) => {
      const db = await openDatabase(tableNames);
      const store = db.transaction(params.tableName, "readwrite").objectStore(
        params.tableName
      );
      await store.delete(params.id);
      return params.id;
    },
    {
     onSuccess: async (data, variables) => {
       await queryClient.invalidateQueries([variables.tableName, "all"]);
     },
     onError: (error) => {
       console.error("Error deleting item:", error);
     },
    }
  );

  const deleteAll = useMutation(
    async (tableName: string) => {
      const db = await openDatabase(tableNames);
      const store = db.transaction(tableName, "readwrite").objectStore(tableName);
      await store.clear();
      return tableName;
    },
     {
      onError: (error) => {
        console.error("Error deleting all items:", error);
      },
     }
  );

  return {
    getAllValues,
    addValue,
    updateValue,
    deleteValue,
    deleteAll,
  };
};

export default useIndexedDB;
