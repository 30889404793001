import { OfferData } from 'constants/apiResponses.types';
import { Observable } from './observable';
import { getUtmParams } from '../utils';
import { ELocalStorageKeys } from '../constants/enums';
import { webStorageUtil } from './webStorage.state.service';

class AppStateService {
  readonly displayLoader = new Observable<boolean>(true);
  readonly showConfetti = new Observable<boolean>(false);
  readonly isProfileEnabled = new Observable<boolean>(false);
  readonly failedHashValidation = new Observable<boolean>(false);
  readonly selectedOffer = new Observable<OfferData | null>();
  readonly isIntegrationProfilesEnabled = new Observable<boolean>(
    webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE) !== null
  );
  readonly backgroundProps = new Observable<object>({});
  readonly collectedProductsImages = new Observable<string[]>([]);
  readonly infoModalUrl = new Observable<string | null>();
  readonly utms = new Observable<Record<string, string>>(getUtmParams());
  readonly isFromCampaign = new Observable<boolean>(false);
  readonly shouldShowApprovalModal = new Observable<boolean>(false);

  clear = () => {
    this.displayLoader.set(false);
    this.failedHashValidation.set(false);
    this.showConfetti.set(false);
    this.isProfileEnabled.set(false);
    this.selectedOffer.set(null);
    this.backgroundProps.set({});
    this.collectedProductsImages.set([]);
    this.infoModalUrl.set(null);
    this.isFromCampaign.set(false);
    this.shouldShowApprovalModal.set(false);
    this.utms.set({});
  };

  setDisplayLoader = (showLoader: boolean) => this.displayLoader.set(showLoader);
  setShowConfetti = (show: boolean) => this.showConfetti.set(show);
  setIsProfileEnabled = (isEnabled: boolean) => this.isProfileEnabled.set(isEnabled);
  setSelectedOffer = (offer: OfferData | null) => this.selectedOffer.set(offer);
  setFailedHashValidation = (failed: boolean) => this.failedHashValidation.set(failed);
  setIsIntegrationProfilesEnabled = (showIntegrationProfiles: boolean) =>
    this.isIntegrationProfilesEnabled.set(showIntegrationProfiles);
  setBackgroundProps = (background: object) => this.backgroundProps.set(background);
  setCollectedProductsImages = (images: string[]) => this.collectedProductsImages.set(images);
  setInfoModalUrl = (url?: string) => this.infoModalUrl.set(url || null);
  setUtms = (utm?: Record<string, string>) => this.utms.set(utm || {});
  setIsFromCampaign = (campaign: boolean) => this.isFromCampaign.set(campaign);
  setShouldShowApprovalModal = (show: boolean) => this.shouldShowApprovalModal.set(show);
}

export const appStateService = new AppStateService();
