import { Box, Button, IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { ActionButton, MessagePage, EFontWeights, BackToGameButton } from '@appcharge/shared-ui';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
  ECheckoutPageEvent,
  EErrorCodes,
  EEventsType,
  ELocalStorageKeys,
  EQueryParams,
  EResultOptions,
  EStorePhase,
  ESupportType,
  EOrderStatus,
} from '../../constants/enums';
import useApi from '../../hooks/useApi';
import { getPlatformData, sendMessageToIframeParent } from '../../utils';
import { webStorageUtil } from 'state/webStorage.state.service';
import { BootResponse, CompletedScreenTheme } from '../../constants/apiResponses.types';
import useUrlQuery from '../../hooks/useUrlQuery';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { useAppState } from 'state/hooks/app.state.hook';
import { StyledEnrichedMsg, StyledTextContainer } from './style';

interface IFailedProps {
  errorCodeProp?: EErrorCodes | EOrderStatus | string;
  orderIdProp?: string;
  isIframeProp?: boolean;
  messageProp?: string;
  currencyCodeProp?: string;
}

export const Failed: React.FC<IFailedProps> = ({
  errorCodeProp,
  orderIdProp,
  isIframeProp = false,
  messageProp,
  currencyCodeProp,
}) => {
  const API = useApi({});
  const { t } = useTranslation();
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const theme = publisherMetaData?.storeTheme?.completedScreen as CompletedScreenTheme;
  const customEvents = useCustomEvents();
  const isIframe = (useUrlQuery(EQueryParams.IS_IFRAME) as string) === 'true' || isIframeProp;
  const msg = (useUrlQuery(EQueryParams.MESSAGE) as string) || messageProp;
  const orderId = (useUrlQuery(EQueryParams.ORDER_ID) as string) || orderIdProp;
  const currencyCode = (useUrlQuery(EQueryParams.CURRENCY_CODE) as string) || currencyCodeProp;
  const error = (useUrlQuery(EQueryParams.ERROR) as EErrorCodes | EOrderStatus) || errorCodeProp;
  const navigate = useNavigate();
  const isUserLoggedIn = webStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) !== null;
  const { setDisplayLoader, setBackgroundProps } = useAppState();
  const location = useLocation();

  const dic: Record<string, Record<string | EOrderStatus, string>> = {
    headline: {
      storeFailure: t('failed.somethingWentWrong'),
      default: t('failed.somethingWentWrong'),
      [EOrderStatus.CHARGE_FAILED]: t('failed.somethingWentWrong'),
      [EOrderStatus.PAYMENT_FAILED]: t('failed.ohNo'),
      auth: t('failed.whoops'),
    },
    lines: {
      storeFailure: '',
      default: t('failed.somethingWentWrong'),
      [EOrderStatus.CHARGE_FAILED]: t('failed.paymentSuccessful'),
      [EOrderStatus.PAYMENT_FAILED]: t('failed.paymentFailed'),
      auth: t('failed.unableToAccessStore'),
    },
  };

  const sanitizedEnrichedMsg = useMemo(() => {
    if (location.state?.enrichedCustomMsg) {
      return DOMPurify.sanitize(
        DOMPurify.sanitize(marked(location.state?.enrichedCustomMsg, { breaks: true }) as string)
      );
    }
    return '';
  }, [location.state?.enrichedCustomMsg]);

  const messageText = useMemo(() => {
    if (sanitizedEnrichedMsg) {
      return undefined;
    }
    if (error) {
      return msg ? [msg] : [dic.lines[error]];
    }
    return [dic.lines.default];
  }, [sanitizedEnrichedMsg, error, msg, dic.lines]);

  const footerSettings = useMemo(
    () => publisherMetaData.storeTheme.general?.footer,
    [publisherMetaData.storeTheme.general?.footer]
  );

  useEffect(() => {
    setDisplayLoader(false);

    const interval = setInterval(() => {
      window.parent.postMessage('iframe-loaded', '*');
    }, 500);

    const handleMessage = (event: { data: string }) => {
      if (event.data === 'stop-messages') {
        clearInterval(interval);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      clearInterval(interval);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!publisherMetaData?.storeTheme) return;

    setBackgroundProps({
      height: '',
      minHeight: '100vh',
      backgroundImageMobile: publisherMetaData.storeTheme.general.backgroundImageMobile,
      backgroundImageDesktop: publisherMetaData.storeTheme.general.backgroundImageDesktop,
    });
  }, [publisherMetaData?.storeTheme]);

  const returnToShop = () => {
    if (isIframe) {
      sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_STORE, {
        isOrderSucceed: false,
      });
    } else {
      navigate('../login');
    }
  };

  const openSupport = () => {
    customEvents.sendCustomEvent(
      EEventsType.SUPPORT_FORM_OPEN,
      {
        phase: EStorePhase.PRE_LOGIN,
        supportModel: publisherMetaData.supportConfiguration?.supportModel,
      },
      EStorePhase.PRE_LOGIN
    );
    if (footerSettings?.supportLink) {
      API.sendEmptySupport(
        publisherMetaData.storeTheme.publisherId,
        ESupportType.PRE_LOGIN,
        EStorePhase.PRE_LOGIN
      );
      window.location.href = footerSettings?.supportLink;
    } else {
      if (isIframe) {
        sendMessageToIframeParent(ECheckoutPageEvent.SUPPORT, {
          supportUrl: `/support/${ESupportType.POST_LOGIN}/${EStorePhase.POST_ORDER}`,
        });
      } else {
        navigate(`/support/${ESupportType.PRE_LOGIN}/${EStorePhase.PRE_LOGIN}`);
      }
    }
  };

  useEffect(() => {
    if (error === EErrorCodes.AUTH || error === EErrorCodes.PAYMENT) return;
    customEvents.sendCustomEvent(
      EEventsType.ERROR,
      {
        msg: msg,
        error: JSON.stringify(error),
      },
      isUserLoggedIn ? EStorePhase.POST_LOGIN : EStorePhase.PRE_LOGIN
    );
    if (error === EOrderStatus.PAYMENT_FAILED || error === EOrderStatus.CHARGE_FAILED) {
      customEvents.sendCustomEvent(
        EEventsType.COMPLETE_SCREEN_PRESENTED,
        {
          status: EResultOptions.FAILED,
          currency_code: currencyCode,
          platform: getPlatformData(),
          order_id: orderId,
        },
        EStorePhase.POST_LOGIN
      );
    }
  }, [error]);

  const deepLinks = useMemo(
    () => publisherMetaData?.integration?.deepLinks,
    [publisherMetaData?.integration?.deepLinks]
  );

  const isBackToGameBtnShown =
    publisherMetaData?.integration.backToGameButtonOn && (isIOS || isAndroid || !isMobile);

  const backToStore = () => {
    if (isIframe) {
      sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_STORE, {
        isOrderSucceed: false,
      });
    } else {
      navigate('../shop');
    }
  };

  return (
    <>
      {isIframe ? (
        <Box className={'checkout-failed'}>
          <Box
            maxWidth={'500px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            padding={'20px'}
          >
            <div className={'checkout-failed__close-icon'}>
              <IconButton onClick={backToStore} size="small">
                <CloseIcon style={{ fontSize: 40, color: 'white' }} />
              </IconButton>
            </div>
            <StyledTextContainer
              headerColor={'white'}
              headerSize={theme.headerSize}
              headerWeight={theme.headerWeight}
              textColor={theme.textColor}
              textSize={theme.textSize}
              textWeight={theme.textWeight}
              className={'checkout-completion__text-container'}
            >
              <h1
                className={'checkout-completion__text-container__header'}
                data-testid={'failure-page-header'}
              >
                {error ? dic.headline[error] : dic.headline.default}
              </h1>
              <h2
                className={'checkout-completion__text-container__sub-header'}
                data-testid={'failure-page-desc'}
              >
                {error ? (msg ? [msg] : [dic.lines[error]]) : [dic.lines.default]}
              </h2>
            </StyledTextContainer>
            <Button
              variant="text"
              fullWidth
              sx={{
                color: 'white',
                fontSize: '12px',
                border: '1px solid #fff',
                minWidth: '100%',
                padding: '13px',
              }}
              onClick={openSupport}
            >
              {t('contactSupport')}
            </Button>
            {orderId && (
              <div
                style={{
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
                className={'checkout-failed__payment-reference'}
                data-testid={'failure-page-desc1'}
              >
                <span>{t('paymentReferenceID')}</span>
                <br />
                <span>{orderId}</span>
              </div>
            )}
          </Box>
        </Box>
      ) : (
        <MessagePage
          headerText={error ? dic.headline[error] : dic.headline.default}
          headerColor={{ colorOne: 'white' }}
          headerSize={isMobile ? 32 : 44}
          text={messageText}
          textColor={'white'}
          textSize={16}
          fontFamily={publisherMetaData.storeTheme.general.font as string}
          headerWeight={'bold' as EFontWeights}
          textWeight={'bold' as EFontWeights}
          dataTestId={{
            header: 'failure-page-header',
            line: 'failure-page-desc',
          }}
        >
          <Stack direction="column" sx={{ alignItems: 'center' }}>
            {sanitizedEnrichedMsg && (
              <StyledEnrichedMsg
                textColor={'white'}
                textSize={16}
                textWeight={'bold'}
                dangerouslySetInnerHTML={{ __html: sanitizedEnrichedMsg }}
              />
            )}
            <ActionButton
              border="2px solid white"
              borderRadius="7px"
              action={returnToShop}
              width="340px"
              text={t('returnToShop')}
              colors={{ colorOne: 'transparent' }}
              marginBottom="16px"
            />
            {isBackToGameBtnShown && (
              <BackToGameButton
                useNewCheckout={false}
                border="2px solid white"
                borderRadius="7px"
                width="340px"
                btnText={t('backToGame')}
                btnColor={{ colorOne: 'transparent' }}
                deepLinks={deepLinks}
              />
            )}
            {(error === EOrderStatus.CHARGE_FAILED || error === EOrderStatus.PAYMENT_FAILED) && (
              <div
                data-testid={'failure-page-desc1'}
                style={{
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '100',
                }}
              >
                <span>{t('paymentReferenceID')}</span>
                <br />
                <span>{orderId}</span>
              </div>
            )}
          </Stack>
        </MessagePage>
      )}
    </>
  );
};
