import React, { useEffect, useState } from 'react';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import '../style.scss';
import { EConfigs, EQueryParams } from 'constants/enums';
import useApi from 'hooks/useApi';
import useUrlQuery from 'hooks/useUrlQuery';
import { RollingOffer } from 'components/RollingOffer/RollingOffer';
import { AnimatePresence, motion } from 'framer-motion';
import { getTemplateProperty } from '../utils';

interface IRollingOffersWrapperProps {
  selectOffer: (offer: OfferData) => void;
}

export const RollingOffersWrapper: React.FC<IRollingOffersWrapperProps> = ({ selectOffer }) => {
  const campaignOfferId = useUrlQuery(EQueryParams.OFFER_ID);
  const [rollingOffersList, setRollingOffersList] = useState<OfferData[]>([]);

  const API = useApi({});

  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;

  useEffect(() => {
    if (!API.getOffers.data?.offers?.rolling || campaignOfferId) return;

    setRollingOffersList(API.getOffers.data.offers.rolling);
  }, [API.getOffers.data?.offers?.rolling, campaignOfferId]);

  return (
    <AnimatePresence>
      {rollingOffersList.length > 0 && (
        <motion.div
          key={'rolling-offer-wrapper-animation'}
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5 }}
          className={'rolling-offers-wrapper'}
          style={{
            maxWidth: getTemplateProperty(EConfigs.desktopMaxWidth, bundlesInternalViewModel),
          }}
        >
          <AnimatePresence>
            {rollingOffersList.map((rollingOffer: OfferData) => (
              <motion.div
                key={rollingOffer.offerId}
                initial={{ opacity: 1, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.5 }}
              >
                <RollingOffer
                  key={rollingOffer.offerId}
                  data={rollingOffer}
                  selectOffer={() => selectOffer(rollingOffer)}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
