import styled from 'styled-components';

interface StyledTextContainerProps {
  headerColor: string;
  headerSize: number;
  headerWeight: number | string;
  textColor: string;
  textSize: number;
  textWeight: number | string;
}

export const StyledTextContainer = styled.div<StyledTextContainerProps>`
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .checkout-completion__text-container {
    &__header {
      margin: unset;
      color: ${(props: any) => props.headerColor};
      font-size: ${(props: any) => props.headerSize + 'px'};
      font-weight: ${(props: any) => props.headerWeight};
    }

    &__sub-header {
      color: ${(props: any) => props.textColor};
      font-size: ${(props: any) => props.textSize + 'px'};
      font-weight: ${(props: any) => props.textWeight};
    }
  }
`;

interface StyledEnrichedMsgProps {
  textColor: string;
  textSize: number;
  textWeight: number | string;
}

export const StyledEnrichedMsg = styled.div<StyledEnrichedMsgProps>`
  font-size: ${(props: any) => props.textSize + 'px'};
  font-weight: ${(props: any) => props.textWeight};;
  color: ${(props: any) => props.textColor};
  
  a {
    color: ${(props: any) => props.textColor};
    text-decoration-color: ${(props: any) => props.textColor};;
  }
`;
