import React from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { ELoginEvents, ELoginMethods, ESessionStorageKeys } from 'constants/enums';
import { LoginButton } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import useLoginEvents from 'hooks/useLoginEvents';
import { webStorageUtil } from 'state/webStorage.state.service';
import { useLoginState } from 'state/hooks/login.state.hook';
import { useAppState } from 'state/hooks/app.state.hook';

export const GoogleLogin: React.FC = () => {
  const { hasConsent, loginResponse, loginButtonClickWrapper } = useLoginState();
  const { setDisplayLoader } = useAppState();
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();

  const { t } = useTranslation();
  const handleNonOAuthError = async () => {
    setDisplayLoader(false);
    sendPublisherWebhookLoginEvent({
      eventName: ELoginEvents.LOGIN_CANCELED,
      method: ELoginMethods.GOOGLE,
    });
  };

  const googleLoginClickHandler = useGoogleLogin({
    onSuccess: (tokenResponse) => googleResponseHandler(tokenResponse),
    onNonOAuthError: handleNonOAuthError,
  });

  const googleResponseHandler = (response: TokenResponse) => {
    if (!response.access_token) return;

    const data = {
      userToken: response.access_token,
      authMethod: ELoginMethods.GOOGLE,
    };
    webStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
    loginResponse(data);
  };

  return (
    <LoginButton
      variant="google"
      callback={() => loginButtonClickWrapper(googleLoginClickHandler, ELoginMethods.GOOGLE)}
      text={t('login.signInWithGoogle')}
      dataTestId="google-login-button"
      isDisabled={!hasConsent}
      opacityOnDisabled={false}
    >
      <img src="https://media.appcharge.com/defaults/google_icon.svg" alt="google icon" />
    </LoginButton>
  );
};
