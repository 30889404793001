import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  getIntegrationProfileOptionStyles,
  StyledIntegrationProfilePickerContainer,
  StyledIntegrationProfileSelect,
} from '../style';
import useApi from 'hooks/useApi';
import { FC, useEffect } from 'react';
import { ELocalStorageKeys } from 'constants/enums';
import { BootResponse } from 'constants/apiResponses.types';
import { webStorageUtil } from 'state/webStorage.state.service';
import { DEFAULT_PROFILE_NAME } from 'constants/defaults';

interface IntegrationProfilePickerProps {
  disabled?: boolean;
}

export const IntegrationProfilePicker: FC<IntegrationProfilePickerProps> = ({ disabled }) => {
  const API = useApi({});
  const currentProfile = webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE);
  const profiles = API.getProfiles;
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const fontFamily =
    publisherMetaData.storeTheme.login.font || publisherMetaData.storeTheme.general.font;
  const handleSelect = (event: SelectChangeEvent<string>) => {
    webStorageUtil.set(ELocalStorageKeys.INTEGRATION_PORFILE, event.target.value);
    window.location.reload();
  };

  useEffect(() => {
    if (!webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE))
      webStorageUtil.set(ELocalStorageKeys.INTEGRATION_PORFILE, DEFAULT_PROFILE_NAME);
    profiles.refetch();
  }, []);

  return (
    <>
      {profiles.data && profiles.data.length > 0 && (
        <StyledIntegrationProfilePickerContainer>
          <Box sx={{ minWidth: '100%' }}>
            <FormControl sx={{ minWidth: '100%' }}>
              <StyledIntegrationProfileSelect
                displayEmpty
                value={currentProfile}
                onChange={handleSelect}
                inputProps={{ 'aria-label': 'Without label' }}
                fontFamily={fontFamily}
                renderValue={(value: string) => `Profile: ${value}`}
                MenuProps={getIntegrationProfileOptionStyles(fontFamily)}
                IconComponent={KeyboardArrowDownIcon}
                disabled={disabled}
              >
                {profiles.data.map((profile) => (
                  <MenuItem key={profile.name} value={profile.name}>
                    {profile.name}
                  </MenuItem>
                ))}
              </StyledIntegrationProfileSelect>
            </FormControl>
          </Box>
        </StyledIntegrationProfilePickerContainer>
      )}
    </>
  );
};
