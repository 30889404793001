export enum ELocalStorageKeys {
  OVERRIDE_STORE = 'overrideStore',
  SESSION_TOKEN = 'sessionToken',
  ANALYTICS = 'analytics',
  USER_LOGIN_TERMS_CONSENT = 'userLoginTermsConsent',
  PLAYER_DATA = 'playerData',
  OFFERS_COOLDOWN = 'offersCooldown',
  PUBLISHER_META = 'publisherMeta',
  ORDER_ID = 'oid',
  OFFER_API_OVERRIDE = 'offersApiOverride',
  BOOT_API_OVERRIDE = 'bootApiOverride',
  POST_LOGIN_API_OVERRIDE = 'postLoginApiOverride',
  OTP_API_OVERRIDE = 'otpApiOverride',
  IS_SAVE_TO_HOME_PAGE_MODAL_OPEN = 'isSaveToHomePageModalOpen',
  STORE_ADD_TO_HOME_SCREEN_NOTIFICATION = 'store_add_to_home_screen_notification',
  CURRENT_AVAILABILITY = 'currentAvailability',
  IS_FREE_ORDER_SELECTED = 'isFreeOrderSelected',
  DAILY_BONUS_BADGES = 'dailyBonusBadges',
  TIME_LEFT = 'timeLeft',
  LAST_SAVED_TIME = 'lastSavedTime',
  CURRENT_LANGUAGE = 'currentLanguage',
  CAMPAIGN_OFFER_ID = 'campaignOfferId',
  LOGIN_START_TIME = 'loginStartTime',
  INTEGRATION_PORFILE = 'integrationProfile',
}

export enum ESessionStorageKeys {
  SESSION_DATA = 'sessionData',
  REDIRECT_STATE = 'redirectState',
  UTM_PARAMS = 'utmParams',
  SOURCE = 'source',
  INSTALL_PROMPT_FIRED = 'installPromptFired',
  D10X_LINK_ID = 'd10x_link_id',
  D10X_ORG = 'd10x_org',
}

export enum EStorageType {
  sessionStorage = 'sessionStorage',
  localStorage = 'localStorage',
}

export enum ESupportModel {
  APPCHARGE = 'AppCharge',
  PLAYER = 'Player',
  PUBLISHER = 'Publisher',
}

export enum ESectionsViewModel {
  DEFAULT = 'default',
  BAGEL_CODE = 'bagelcode',
  PIPA = 'pipa',
  Whispers = 'whispers',
  LLC = 'llc',
  MIGHTY_FU_CASINO = 'mighty-fu-casino',
  GHOST = 'ghost',
}

export enum EBundlesInternalViewModel {
  AK = 'ak',
  MATCH = 'match',
  CANDIVORE = 'candivore',
  COMMUNIX = 'communix',
  COMMUNIX2 = 'communix2',
  MAIN_SUB = 'mainSub',
  BAGELCODE = 'bagelcode',
  BAGELCODE2 = 'bagelcode2',
  LOCO_BINGO = 'locobingo',
  JACKPOT_SLOTS = 'jackpot-slots',
  BIG_FISH_CASINO = 'big-fish-casino',
  CASHMAN_CASINO = 'cashman-casino',
  LIGHTNING_LINK_CASINO = 'lightning-link-casino',
  HEART_OF_VEGAS = 'heart-of-vegas',
  ME2ON = 'me2on',
  PLAY_SIMPLE = 'play-simple',
  PIPA = 'pipa',
  WHISPERS = 'whispers',
  BABA = 'baba',
  MIGHTY_FU_CASINO = 'mighty-fu-casino',
  GHOST = 'ghost',
  BINGO_POP = 'bingo-pop',
}

export enum ERollingOffersInternalViewModel {
  HUGE = 'huge',
}

export enum ESpecialOfferInternalViewModel {
  HUGE = 'hug',
  MATCH_MASTERS = 'match-masters',
  POKER_FACE = 'pokerFace',
  BAGELCODE = 'bagelcode',
  LOCO_BINGO = 'locobingo',
  ME2ON = 'me2on',
  PIPA = 'pipa',
  JACKPOT_SLOTS = 'jackpot-slots',
  BIG_FISH_CASINO = 'big-fish-casino',
  PLAY_SIMPLE = 'play-simple',
  BABA = 'baba',
  GHOST = 'ghost',
  BINGO_POP = 'bingo-pop',
}

export enum EConfigs {
  desktopMaxWidth = 'desktopMaxWidth',
  maxWidthOverride = 'maxWidthOverride',
  gapX = 'gapX',
  gapY = 'gapY',
  marginTop = 'marginTop',
}

export enum ESupportType {
  POST_LOGIN = 'postLogin',
  PRE_LOGIN = 'preLogin',
}

export enum EStorePhase {
  PRE_LOGIN = 'PreLogin',
  POST_LOGIN = 'PostLogin',
  POST_ORDER = 'PostOrder',
  OTHER = 'Other',
}

export enum EPublisherWebhookEventType {
  LOGIN = 'login',
  OFFER = 'offer',
}

export enum EPublisherWebhookOfferEventType {
  OFFERS_SHOWN = 'offers_shown',
  OFFERS_LOADED = 'offers_loaded',
  OFFER_CLICKED = 'offer_clicked',
  POPUP_APPROVAL_PRESENTED = 'popup_approval_presented',
  POPUP_APPROVAL_CLOSED = 'popup_approval_closed',
}

export enum EBigQueryEventsList {
  OFFER_CLICKED = 'offer_clicked',
  OFFERS_LOADED = 'offers_loaded',
  OFFERS_SHOWN = 'offers_shown',
  LOGIN_LAND = 'login_land',
  SHOP_LAND = 'shop_land',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  LOGIN_CANCELED = 'login_canceled',
  LOGIN_RESOLVED = 'login_resolved',
}

export enum EEventsType {
  LOGIN_LAND = 'LOGIN_LAND',
  LOGIN_CLICKED = 'LOGIN_CLICKED',
  LOGIN_RESULT = 'LOGIN_RESULT',
  LOGIN_ATTEMPTED = 'LOGIN_ATTEMPTED',
  LOGIN_CANCELED = 'LOGIN_CANCELED',
  WEBVIEW_LAND = 'WEBVIEW_LAND',
  SHOP_LAND = 'SHOP_LAND',
  PROFILE_MENU_CLICKED = 'PROFILE_MENU_CLICKED',
  LOGOUT = 'LOGOUT',
  ERROR = 'ERROR',
  OFFER_CLICKED = 'OFFER_CLICKED',
  ORDER_RESOLVED = 'ORDER_RESOLVED',
  SUPPORT_FORM_OPEN = 'SUPPORT_FORM_OPEN',
  SUPPORT_FORM_SUBMIT = 'SUPPORT_FORM_SUBMIT',
  SUPPORT_FORM_CANCEL = 'SUPPORT_FORM_CANCEL',
  ERROR_POPUP_PRESENTED = 'ERROR_POPUP_PRESENTED',
  LANGUAGE_SELECTED = 'LANGUAGE_SELECTED',
  OFFERS_LOADED = 'OFFERS_LOADED',
  OFFERS_SHOWN = 'OFFERS_SHOWN',
  OFFERS_IMAGES_LOADED = 'OFFERS_IMAGES_LOADED',
  OFFERS_IMAGE_FAILED_LOADING = 'OFFERS_IMAGE_FAILED_LOADING',
  POP_UP_CLOSED = 'POP_UP_CLOSED',
  COLLECT_CLICKED = 'COLLECT_CLICKED',
  COLLECT_RESOLVED = 'COLLECT_RESOLVED',
  COLLECT_API_SENT = 'COLLECT_API_SENT',
  COLLECT_API_FAILED = 'COLLECT_API_FAILED',
  COMPLETE_SCREEN_PRESENTED = 'COMPLETE_SCREEN_PRESENTED',
  COMPLETE_SCREEN_BACK_TO_GAME = 'COMPLETE_SCREEN_BACK_TO_GAME',
  COMPLETE_SCREEN_BACK_TO_SHOP = 'COMPLETE_SCREEN_BACK_TO_SHOP',
  HOME_SCREEN_BUTTON_CLICK = 'HOME_SCREEN_BUTTON_CLICK',
  PROMOTION_CLICKED = 'PROMOTION_CLICKED',
  STANDALONE_MODE_ENTERED = 'STANDALONE_MODE_ENTERED',
  CHECKOUT_OPENED = 'CHECKOUT_OPENED',
  TOOLTIP_OPEN = 'TOOLTIP_OPEN',
  OUTDATED_OFFER_FAILURE = 'OUTDATED_OFFER_FAILURE',
  DEEPLINK_ACCOUNT_APPROVAL_DISPLAYED = 'DEEPLINK_ACCOUNT_APPROVAL_DISPLAYED',
  DEEPLINK_ACCOUNT_APPROVAL_APPROVE = 'DEEPLINK_ACCOUNT_APPROVAL_APPROVE',
  SSE_CONNECTION_ATTEMPT = 'SSE_CONNECTION_ATTEMPT',
  SSE_CONNECTION_ESTABLISHED = 'SSE_CONNECTION_ESTABLISHED',
  SSE_MESSAGE_RECEIVED = 'SSE_MESSAGE_RECEIVED',
  SSE_UNKNOWN_MESSAGE = 'SSE_UNKNOWN_MESSAGE',
  SSE_CONNECTION_ERROR = 'SSE_CONNECTION_ERROR',
  SSE_MAX_RETRIES_REACHED = 'SSE_MAX_RETRIES_REACHED',
  SSE_POLLING_STARTED = 'SSE_POLLING_STARTED',
}

export enum ECollectType {
  POPUP = 'popup',
  OFFER = 'offer',
  ROLLING = 'rolling',
}

export enum ELogoSize {
  SMALL = 'small',
  LARGE = 'big',
}

export enum EPlatformType {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum EQueryParams {
  MESSAGE = 'msg',
  ORDER_ID = 'order_id',
  ERROR = 'error',
  LOCAL_ADDRESS = 'local_address',
  LOGIN_REDIRECT = 'login_redirect',
  IS_IFRAME = 'is_iframe',
  CURRENCY_CODE = 'currencyCode',
  FROM = 'from',
  TOKEN = 'token',
  OFFER_ID = 'offerId',
  DYNAMO_LINK_ID = 'd10x_link_id',
  DYNAMO_ORG = 'd10x_org',
}

export enum EQueryKeys {
  ORDER = 'order',
  BUNDLES = 'bundles',
  BUNDLE = 'bundle',
  PAYPAL = 'paypal',
  BOOT = 'boot',
  POST_LOGIN = 'post-login',
  OFFERS = 'offers',
  OTP = 'otp',
  FEATURE_FLAG = 'feature-flag',
  PRODUCTS = 'products',
  LANGUAGES = 'languages',
  TRANSLATIONS = 'translations',
  STORE_CAMPAIGN = 'store-campaign',
  PROFILES = 'profiles',
  OFFERS_SSE = 'offers-sse',
}

export enum EOfferType {
  BUNDLE = 'Bundle',
  ROLLING_OFFER = 'RollingOffer',
  SPECIAL_OFFER = 'SpecialOffer',
  POP_UP = 'PopUp',
}

export enum EOrderStatus {
  CREATED = 'created',
  REQUEST_FAILED = 'API request failed',
  PAYMENT_PENDING = 'payment_pending',
  PAYMENT_SUCCEED = 'payment_succeed',
  PAYMENT_FAILED = 'payment_failed',
  CHARGE_PENDING = 'charge_pending',
  CHARGE_SUCCEED = 'charge_succeed',
  CHARGE_FAILED = 'charge_failed',
}

export enum EErrorCodes {
  PAYMENT = 'payment',
  AUTH = 'auth',
}

export enum EPlatform {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}

export enum EResultOptions {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum ELoginResultReason {
  OK = 'OK',
  UNKNOWN = 'unknown',
}

export enum EFeatureFlag {
  STORE_MULTI_LANGUAGE_SUPPORT = 'store_multi_language_support',
  STORE_SHOW_DAILY_BONUS_X_ICON = 'store_present_daily_bonus_x_icon',
  STORE_SEND_OS_AUTH_LOGIN = 'store_send_os_authlogin',
  STORE_SHOW_VERSION = 'store_show_version',
  STORE_REFRESH_OFFERS_STALE_MODE = 'store_refresh_offers_stale_mode',
  STORE_HIDE_MOBILE_BACKGROUND_OVERLAY = 'store_hide_mobile_background_overlay',
  STORE_DEEPLINKS_DISABLE_STORE = 'store_deeplinks_disable_store',
  STORE_UNDER_MAINTENANCE = 'store_under_maintenance',
  STORE_SUCCESS_SCREEN_HIDE_CLOSE_UNTIL_REFETCH = 'store_success_screen_hide_close_until_refetch',
  STORE_REMOVE_BACK_TO_GAME_BUTTON_AFTER_FREE = 'store_remove_back_to_game_button_after_free',
  DASHBOARD_NEW_PRIVACY_RICH_TEXT = 'dashboard_new_privacy_rich_text',
  STORE_ANIMATION_AFTER_PURCHASES = 'store_animation_after_purchases',
  STORE_COLLECT_ANIMATION_POST_FREE_COLLECT_PROMOTIONS_POPUPS = 'store_collect_animation_post_free_collect_promotions_popups',
  STORE_SSE_SUPPORT = 'store_sse_support',
  STORE_USE_NEW_STORE_LAYOUT = 'store_use_new_store_layout',
  STORE_HEADER_V2 = 'store_header_version_2',
  INTEGRATION_PROFILES = 'integration_profiles',
}

export enum ECheckoutPageEvent {
  BACK_TO_STORE = 'backToStore',
  BACK_TO_GAME = 'backToGame',
  SUPPORT = 'support',
  ORDER_CREATED = 'appcharge_order_created',
  PAYMENT_INTENT_SUCCESS = 'appcharge_payment_intent_success',
  PAYMENT_INTENT_FAILED = 'appcharge_payment_intent_failed',
  ORDER_COMPLETED_SUCCESS = 'appcharge_order_completed_success',
  ORDER_COMPLETED_FAILED = 'appcharge_order_completed_failed',
  CLOSE_CHECKOUT = 'appcharge_close_checkout',
  CHECKOUT_OPENED = 'appcharge_checkout_opened',
}

export enum ESaleDiscountDisplayType {
  PERCENTAGE = 'percentage',
  MULTIPLIER = 'multiplier',
  FIXED_AMOUNT = 'fixed_amount',
}

export enum EPopupSubType {
  DAILY_BONUS = 'DailyBonus',
  POST_PURCHASE = 'PostPurchase',
}

export enum ESubRollingOfferCollectBehavior {
  DISAPPEAR = 'disappear',
  STAY = 'stay',
}

export enum ELoginMode {
  PROVIDERS = 'providers',
  USERNAME = 'username',
  TOKEN_ID = 'tokenId',
  OTP = 'otp',
}

export enum ELoginMethods {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
  USERNAME = 'userPassword',
  TOKEN = 'userToken',
  OTP = 'otp',
  DEEP_LINK = 'deepLink',
}

export enum ELoginEvents {
  LOGIN_CANCELED = 'login_canceled',
  LOGIN_LAND = 'login_land',
  LOGIN_RESOLVED = 'login_resolved',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  SHOP_LAND = 'shop_land',
}

export enum EOTPModel {
  STATIC = 'static',
  DYNAMIC_LINK = 'dynamicLink',
}

export enum EOTPTextModel {
  SIX_DIGITS = 'sixDigits',
  FOUR_DIGITS = 'fourDigits',
  MOBILE_ONLY = 'mobileOnly',
}

export enum EAppleResponseType {
  CODE = 'code',
  ID_TOKEN = 'code id_token',
}

export enum StoreBodyBlock {
  dailyBonus = 'dailyBonus',
  playerLevel = 'playerLevel',
  banners = 'banners',
  specialOffers = 'specialOffers',
  rollingOffers = 'rollingOffers',
  bundles = 'bundles',
}

export enum ESSEventType {
  GET_OFFERS_REQUEST = 'getOffersRequest',
  KEEPALIVE_PING = 'keepAlivePing',
}
