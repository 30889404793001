import { useEffect, useState } from 'react';
import { BootResponse } from '../constants/apiResponses.types';
import { isAndroid, isIOS } from 'react-device-detect';
import { ESessionStorageKeys } from 'constants/enums';
import { webStorageUtil } from 'state/webStorage.state.service';

interface InstallPromptEvent extends Event {
  prompt: () => Promise<void>;
}

const useSaveToHomePage = (publisherMetaData: BootResponse) => {
  const [isSaveToHomePageOpen, setIsSaveToHomePageOpen] = useState(false);
  const [isDisplayAddToHomePageButton, setIsDisplayAddToHomePageButton] = useState(
    !!publisherMetaData.storeTheme.storeScreen.addToHomeScreen?.active &&
      (isIOS || isAndroid) &&
      !(webStorageUtil.get(ESessionStorageKeys.SOURCE) === 'home screen bookmark')
  );
  const [installPrompt, setInstallPrompt] = useState<InstallPromptEvent | null>(null);
  const [isAndroidFallBack, setIsAndroidFallBack] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      console.log('beforeinstallprompt event triggered');
      setInstallPrompt(e);
      webStorageUtil.set(ESessionStorageKeys.INSTALL_PROMPT_FIRED, 'true');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const handleAppInstalled = () => {
      setIsDisplayAddToHomePageButton(false);
      webStorageUtil.set(ESessionStorageKeys.SOURCE, 'home screen bookmark');
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleInstallApp = async () => {
    if (installPrompt) {
      try {
        await installPrompt.prompt();
        setInstallPrompt(null);
        webStorageUtil.remove(ESessionStorageKeys.INSTALL_PROMPT_FIRED);
      } catch (error) {
        console.error('Install prompt failed', error);
      }
    } else {
      setIsAndroidFallBack(true);
      console.log('Install prompt not available');
    }
  };

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) {
      setIsDisplayAddToHomePageButton(false);
      setIsSaveToHomePageOpen(false);
      webStorageUtil.set(ESessionStorageKeys.SOURCE, 'home screen bookmark');
    } else if (webStorageUtil.get(ESessionStorageKeys.INSTALL_PROMPT_FIRED)) {
      // Handle case where beforeinstallprompt already fired but page reloaded
      console.log('Restoring install prompt state');
      setIsDisplayAddToHomePageButton(true);
    }
  }, []);

  const handleCloseSaveToHomePage = () => {
    setIsSaveToHomePageOpen(false);
    setIsAndroidFallBack(false);
  };

  return {
    isSaveToHomePageOpen,
    setIsSaveToHomePageOpen,
    isDisplayAddToHomePageButton,
    handleCloseSaveToHomePage,
    handleInstallApp,
    isAndroidFallBack,
  };
};

export default useSaveToHomePage;
