import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIProps, BootResponse, LoginResponse, Profile } from '../constants/apiResponses.types';
import {
  EBigQueryEventsList,
  EEventsType,
  EFeatureFlag,
  ELocalStorageKeys,
  EPlatform,
  EQueryKeys,
  EStorePhase,
  ESupportType,
} from '../constants/enums';
import useAxios from './useAxios';
import { webStorageUtil } from 'state/webStorage.state.service';
import { SupportData } from '../constants/support.types';
import useCustomEvents from './useCustomEvents';
import { isDesktop } from 'react-device-detect';
import {
  CheckoutOrderPayload,
  CreateCheckoutSessionPayload,
  CreateOrderPayload,
  PublisherWebhookEvent,
  SendOrderImpressionPayload,
} from '../constants/apiRequest.types';
import useFEMonitoring from './useFEMonitoring';
import { CACHE_TIME_MS, STALE_TIME_MS } from '../constants/defaults';
import { LoginRequestData } from '../pages/login/types';
import { AxiosError } from 'axios';
import { generateQueryParam } from 'utils';
import { appStateService } from 'state/app.state.service';

const getUtmHeaders = (): Record<string, string> => {
  const utms = appStateService.utms.get();
  if (!utms) return {};
  const utmParams = appStateService.utms.get() || {};
  const headers: Record<string, string> = {};
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign'];
  utmKeys.forEach((key) => {
    const value = utmParams[key];
    if (value) {
      headers[`x-ac-${key}`] = value; // Dynamically set the header key
    }
  });
  return headers;
};

const getLanguageParam = ({
  publisherMetaData,
  initialLanguage,
}: {
  publisherMetaData: BootResponse | undefined;
  initialLanguage: string;
}): string => {
  const supportMultiLanguageEnabled = publisherMetaData?.featureFlags?.store_multi_language_support;

  if (!supportMultiLanguageEnabled) return '';

  return `?language=${initialLanguage}`;
};

export default function useApi({ orderId, platform, isSSE }: APIProps) {
  const axios = useAxios();
  const customEvents = useCustomEvents();
  const { logToNewRelic } = useFEMonitoring();
  const queryClient = useQueryClient();
  const initialLanguage = webStorageUtil.get(ELocalStorageKeys.CURRENT_LANGUAGE);
  const publisherMetaData = webStorageUtil.get(ELocalStorageKeys.PUBLISHER_META);

  const getOffers = useQuery({
    queryKey: [EQueryKeys.OFFERS],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      const query = generateQueryParam({ language: initialLanguage });

      try {
        if (webStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE))
          return JSON.parse(webStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE));

        const result = isSSE
          ? await axios.get(
              `/store/offers${query}`,
              false,
              {},
              { 'x-sse-event': 'getOfferRequest' }
            )
          : await axios.get(`/store/offers${query}`);

        return result;
      } catch (err) {
        customEvents.sendCustomEvent(
          EEventsType.ERROR,
          {
            type: 'api error',
            route: 'getOffers',
            error: JSON.stringify(err),
          },
          EStorePhase.POST_LOGIN
        );
        throw err;
      }
    },
  });

  const getPublisherMeta = useQuery<BootResponse, Error>({
    queryKey: EQueryKeys.BOOT,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      const query = generateQueryParam({ language: initialLanguage });
      const response = await axios
        .get(
          `store/${
            webStorageUtil.get(ELocalStorageKeys.OVERRIDE_STORE) || window.location.host
          }/boot${query}`,
          true
        )
        .catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getPublisherMeta',
              error: JSON.stringify(err),
            },
            EStorePhase.PRE_LOGIN
          );
          logToNewRelic(err);
          throw err;
        });
      webStorageUtil.set(ELocalStorageKeys.PUBLISHER_META, response);
      return webStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE)
        ? JSON.parse(webStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE))
        : response;
    },
  });

  const getProfiles = useQuery<Profile[], Error>({
    queryKey: EQueryKeys.PROFILES,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      const publisherId = publisherMetaData.storeTheme.publisherId;
      if (!publisherId) return;
      const response = await axios
        .get(`store/profiles/publisherId/${publisherId}`, true)
        .catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getProfiles',
              error: JSON.stringify(err),
            },
            EStorePhase.PRE_LOGIN
          );
          logToNewRelic(err);
          throw err;
        });
      return response;
    },
  });

  const getUtms = (accessToken: string) => {
    const query = generateQueryParam({ accessToken });

    return axios
      .get(
        `store/domain/${
          webStorageUtil.get(ELocalStorageKeys.OVERRIDE_STORE) || window.location.host
        }/utms${query}`,
        true
      )
      .catch((err) => {
        if (err?.response?.status === 410 || err?.response?.status === 404)
          return new Promise((resolve) => {
            resolve({});
          });
        customEvents.sendCustomEvent(EEventsType.ERROR, {
          type: 'api error',
          route: 'utms',
          error: JSON.stringify(err),
        });
        throw err;
      });
  };

  const getOTP = () => {
    const otpApiOverride = webStorageUtil.get(ELocalStorageKeys.OTP_API_OVERRIDE);
    if (otpApiOverride) {
      return new Promise((resolve) => {
        resolve({
          data: otpApiOverride,
        });
      });
    }

    const utmParams = appStateService.utms.get();

    const utm = {
      ...(utmParams?.utm_source ? { utmSource: utmParams.utm_source } : {}),
      ...(utmParams?.utm_medium ? { utmMedium: utmParams.utm_medium } : {}),
      ...(utmParams?.utm_campaign ? { utmCampaign: utmParams.utm_campaign } : {}),
    };
    const profileName = webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE);
    const isProfilesEnabled = getPublisherMeta.data?.integration.isProfilesEnabled;
    const hasFeatureFlag = publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES];

    const platformBase = platform?.toUpperCase() || '';
    const profileSuffix =
      isProfilesEnabled && profileName && hasFeatureFlag ? `::${profileName}` : '';

    const platformValue = `${platformBase}${profileSuffix}`;
    return axios
      .post(
        `/store/otp/deeplink`,
        {
          data: {
            device: isDesktop ? EPlatform.DESKTOP : 'APPCHARGE',
            publisherId: webStorageUtil.get(ELocalStorageKeys.PUBLISHER_META).storeTheme
              .publisherId,
            platform: platformValue,
            utm,
          },
          headers: {},
        },
        true
      )
      .catch((err) => {
        return logToNewRelic(err)
          .then(() => {
            return customEvents.sendCustomEvent(
              EEventsType.ERROR,
              {
                type: 'api error',
                route: 'getOTP',
                error: JSON.stringify(err),
              },
              EStorePhase.PRE_LOGIN
            );
          })
          .finally(() => {
            throw err;
          });
      });
  };

  const getPostLoginData = useQuery({
    queryKey: EQueryKeys.POST_LOGIN,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      return webStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE)
        ? JSON.parse(webStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE))
        : await axios.get('/store/postLogin').catch((err) => {
            customEvents.sendCustomEvent(
              EEventsType.ERROR,
              {
                type: 'api error',
                route: 'getPostLoginData',
                error: JSON.stringify(err),
              },
              EStorePhase.POST_LOGIN
            );
            logToNewRelic(err);
            throw err;
          });
    },
  });

  const getOrder = useQuery({
    queryKey: `${EQueryKeys.ORDER}_${orderId}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      return await axios.get(`store/orders/${orderId}`).catch((err) => {
        customEvents.sendCustomEvent(
          EEventsType.ERROR,
          {
            type: 'api error',
            route: 'getOrder',
            error: JSON.stringify(err),
          },
          EStorePhase.POST_LOGIN
        );
        logToNewRelic(err);
        throw err;
      });
    },
  });

  const getProductsAssets = (arrProductIds: string[]) => {
    const idsUrlParam = encodeURIComponent(arrProductIds.join(','));
    const queryKey = `${EQueryKeys.PRODUCTS}_${idsUrlParam}`;
    return queryClient.fetchQuery(
      queryKey,
      async () => {
        if (!arrProductIds || !arrProductIds.length) return [];
        return axios.get(`store/products/external-ids/${idsUrlParam}`).catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getProducts',
              error: JSON.stringify(err),
            },
            EStorePhase.OTHER
          );
          logToNewRelic(err);
          throw err;
        });
      },
      { cacheTime: 60000, staleTime: 60000 }
    );
  };

  const createCheckoutSession = useMutation(async (data: CreateCheckoutSessionPayload) => {
    const headers = getUtmHeaders();

    // Check if dynamoPayload exists in session storage
    const dynamoPayload = sessionStorage.getItem('dynamoPayload');
    if (dynamoPayload && dynamoPayload.trim() !== '') {
      headers['x-dynamo-payload'] = dynamoPayload;
      sessionStorage.removeItem('dynamoPayload');
    }

    const languageApiAddition = getLanguageParam({
      publisherMetaData: getPublisherMeta?.data,
      initialLanguage,
    });

    return await axios.post(`/store/orders/checkout-session/v2${languageApiAddition}`, {
      data,
      headers,
    });
  }, {});

  const createOrder = useMutation(async (data: Partial<CreateOrderPayload>): Promise<any> => {
    const languageApiAddition = getLanguageParam({
      publisherMetaData: getPublisherMeta?.data,
      initialLanguage,
    });

    return await axios.post(`/store/orders${languageApiAddition}`, {
      data,
      headers: {},
    });
  }, {});

  const checkoutOrder = useMutation(
    async (data: CheckoutOrderPayload) => {
      return await axios.post(`/store/orders/checkout`, {
        data,
        headers: {},
      });
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err);
      },
    }
  );

  const getStoreCampaign = useMutation(
    async (data: { tokenId: string; linkId?: string; org?: string }) => {
      return await axios.get(`/store/campaign/${data.tokenId}`);
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err);
      },
    }
  );

  const login = useMutation(
    async (data: Partial<LoginRequestData>) => {
      const headers = getUtmHeaders();

      return await axios.post<LoginResponse>('/store/auth/login', { data, headers }, true);
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err);
      },
    }
  );

  // Used for deeplink login when user doesn't have active login session
  const temporaryLogin = useMutation(
    async (data: { sessionId: string; jwt: string; publisherId: string }) => {
      const headers = getUtmHeaders();
      return await axios.post<LoginResponse>('/store/auth/login/external', { data, headers });
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err);
      },
    }
  );

  const logout = useMutation(
    async () => {
      return await axios.post(
        '/store/auth/logout',
        {
          data: {},
          headers: {},
        },
        false
      );
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err);
      },
    }
  );

  const keepAlive = useMutation({
    retry: false,
    mutationFn: async () => {
      return await axios.post('/store/auth/keepalive', {
        data: {},
        headers: {},
      });
    },
  });

  const sendSupport = useMutation(
    async ({ data, supportType }: { data: SupportData; supportType: ESupportType }) => {
      const url =
        supportType === ESupportType.POST_LOGIN
          ? 'store/support/postlogin'
          : 'store/support/prelogin';
      return await axios.post(url, {
        data: data,
        headers: {},
      });
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err);
      },
    }
  );

  const sendEmptySupport = (
    publisherId: string,
    supportPhase: ESupportType,
    storePhase: EStorePhase,
    orderId?: string
  ) => {
    sendSupport.mutateAsync(
      {
        data: {
          publisherId: publisherId,
          supportFullName: '',
          communicationType: '',
          communicationDetails: '',
          supportRequestReason: '',
          playerStorePhase: storePhase,
          playerMessage: '',
          orderId: orderId || '',
        },
        supportType: supportPhase,
      },
      {
        onError: async (err: AxiosError) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'sendSupport',
              error: JSON.stringify(err),
            },
            storePhase
          );
          await logToNewRelic(err);
        },
      }
    );
  };

  const sendPublisherWebhookEvent = useMutation(async (event: PublisherWebhookEvent) => {
    const integration = getPublisherMeta.data?.integration;
    if (
      (!integration ||
        !integration.eventsWebhookUrl ||
        !integration.enabledWebhookEvents.includes(event.data.eventName)) &&
      !Object.values(EBigQueryEventsList).includes(event.data.eventName as any)
    ) {
      return;
    }
    return await axios
      .post(`/store/events/${event.eventType}`, {
        data: event.data,
        headers: {},
      })
      .catch((err: AxiosError) => {
        return logToNewRelic(err).then(() => {
          console.error(err);
        });
      });
  }, {});

  const sendShopLand = useMutation(async () => {
    return await axios
      .post('/store/analytics/shop-land', {
        data: {},
        headers: {},
      })
      .catch((err: AxiosError) => {
        return logToNewRelic(err).then(() => {
          console.error(err);
        });
      });
  }, {});

  const sendOrderImpression = (data: SendOrderImpressionPayload) => {
    const url = 'store/event';
    return axios
      .post(url, {
        data: {
          eventName: 'impression',
          ...data,
        },
        headers: {},
      })
      .catch((err: AxiosError) => {
        return logToNewRelic(err).then(() => {
          console.error(err);
        });
      });
  };

  const getLanguagesList = (publisherId: string) => {
    return queryClient.fetchQuery(
      `${EQueryKeys.LANGUAGES}`,
      async () => {
        return axios.get(`store/translations/${publisherId}/languages`, true).catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getLanguagesList',
              error: JSON.stringify(err),
            },
            EStorePhase.OTHER
          );
          logToNewRelic(err);
          throw err;
        });
      },
      { cacheTime: CACHE_TIME_MS, staleTime: STALE_TIME_MS }
    );
  };

  const getCurrentTranslations = (publisherId: string, language: string) => {
    return queryClient.fetchQuery(
      `${EQueryKeys.TRANSLATIONS}`,
      async () => {
        return axios
          .get(`store/translations/${publisherId}/languages/${language}`, true)
          .catch((err) => {
            customEvents.sendCustomEvent(
              EEventsType.ERROR,
              {
                type: 'api error',
                route: 'getCurrentTranslations',
                error: JSON.stringify(err),
              },
              EStorePhase.OTHER
            );
            logToNewRelic(err);
            throw err;
          });
      },
      { cacheTime: CACHE_TIME_MS, staleTime: STALE_TIME_MS }
    );
  };

  return {
    login,
    temporaryLogin,
    logout,
    sendShopLand,
    getProfiles,
    getProductsAssets,
    getOrder,
    getOffers,
    getStoreCampaign,
    keepAlive,
    sendSupport,
    createOrder,
    checkoutOrder,
    createCheckoutSession,
    getUtms,
    getOTP,
    getPublisherMeta,
    getPostLoginData,
    sendEmptySupport,
    sendPublisherWebhookEvent,
    sendOrderImpression,
    getLanguagesList,
    getCurrentTranslations,
  };
}
