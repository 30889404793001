import mixpanel, { Dict } from 'mixpanel-browser';
import { webStorageUtil } from 'state/webStorage.state.service';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DeviceDetector from 'device-detector-js';
import {
  EFeatureFlag,
  ELocalStorageKeys,
  EPlatform,
  ESessionStorageKeys,
  EStorePhase,
} from '../constants/enums';
import { EventSharedProps } from 'constants/apiRequest.types';
import { useMemo, useState } from 'react';
import { getAppVersion } from 'utils';
import { appStateService } from 'state/app.state.service';

export interface UseCustomEventsReturn {
  sendCustomEvent: (eventType: string, options: Dict, eventPhase?: EStorePhase) => void;
  getEventsSharedProps: () => EventSharedProps;
  setDistinctId: () => void;
  resetSession: () => void;
}

interface EventSharedPropsState {
  platform: string;
  deviceManufacturer?: string;
  deviceModel?: string;
  os?: string;
  osVersion?: string;
  browser?: string;
  browserVersion?: string;
  storeVersion?: string;
  sharedUiVersion?: string;
  checkoutSdkVersion?: string;
  appVersion?: string;
}

export default function useCustomEvents(): UseCustomEventsReturn {
  const { i18n } = useTranslation();
  const publisherMetaData = webStorageUtil.get(ELocalStorageKeys.PUBLISHER_META);
  const [sharedProps] = useState<EventSharedPropsState>(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const deviceInfo = deviceDetector.parse(userAgent);

    return {
      platform: isMobile ? EPlatform.MOBILE : isTablet ? EPlatform.TABLET : EPlatform.DESKTOP,
      deviceManufacture: deviceInfo.device?.brand,
      deviceModel: deviceInfo.device?.model,
      os: deviceInfo.os?.name,
      osVersion: deviceInfo.os?.version,
      browser: deviceInfo.client?.name,
      browserVersion: deviceInfo.client?.version,
      storeVersion: process.env.REACT_APP_STORE_VERSION,
      sharedUiVersion: process.env.REACT_APP_SHARED_UI_VERSION,
      checkoutSdkVersion: process.env.REACT_APP_CHECKOUT_SDK_VERSION,
      appVersion: getAppVersion(),
    };
  });
  const profileName = useMemo(() => {
    if (
      publisherMetaData?.integration.isProfilesEnabled &&
      publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES]
    )
      return webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE);
    return '';
  }, [
    publisherMetaData?.integration.isProfilesEnabled,
    publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES],
  ]);

  const getPlatform = (): string => {
    return isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
  };

  const getEventsSharedProps = (): EventSharedProps => {
    const timestamp = new Date().getTime();
    const playerData = webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA);
    const sessionId = webStorageUtil.get(ESessionStorageKeys.SESSION_DATA)?.id;
    const platform = `${sharedProps.platform.toUpperCase()}${profileName ? `::${profileName}` : ''}`;

    return {
      ...sharedProps,
      sessionId,
      timestamp,
      playerId: playerData?.playerId,
      language: i18n.language,
      platform,
    };
  };

  const enrichOptions = (
    options: Dict,
    utmParams: Record<string, string>,
    platform: string
  ): Dict => {
    return {
      ...options,
      environment: process.env.REACT_APP_ENV || 'local',
      publisher_id: publisherMetaData.storeTheme.publisherId,
      URL: window.location.origin,
      platform,
      language: i18n.language,
      store_version: process.env.REACT_APP_STORE_VERSION,
      shared_ui_version: process.env.REACT_APP_SHARED_UI_VERSION,
      checkout_sdk_version: process.env.REACT_APP_CHECKOUT_SDK_VERSION,
      utmSource: utmParams.utm_source || null,
      utmMedium: utmParams.utm_medium || null,
      utmCampaign: utmParams.utm_campaign || null,
    };
  };

  const sendCustomEvent = (
    eventType: string,
    options: Dict,
    eventPhase: EStorePhase = EStorePhase.OTHER
  ): void => {
    const platform = getPlatform();
    const utmParams = appStateService.utms.get() || {};
    const enrichedOptions = enrichOptions(options, utmParams, platform);

    if (eventPhase === EStorePhase.POST_LOGIN) {
      const playerData = webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA);
      const sessionData = webStorageUtil.get(ESessionStorageKeys.SESSION_DATA);

      if (playerData) {
        enrichedOptions.player_id = playerData.playerId;
        enrichedOptions.player_country = playerData.playerCountry;
      }

      if (sessionData?.id) {
        enrichedOptions.session_id = sessionData.id;
      }
    }

    // if (enrichedOptions.environment === 'production') {
    mixpanel.track(eventType, enrichedOptions);
    // }
  };

  const setDistinctId = () => {
    const playerData = webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA);
    mixpanel.identify(`${publisherMetaData?.storeTheme?.publisherId}_${playerData?.playerId}`);
  };

  const resetSession = () => {
    mixpanel.reset();
  };

  return {
    sendCustomEvent,
    setDistinctId,
    resetSession,
    getEventsSharedProps,
  };
}
